const statusList = [
  {
    "order_status_id": 1,
    "name": "Создан",
    "code": "created"
  },
  {
    "order_status_id": 2,
    "name": "Ожидает готовки",
    "code": "wait_cooking"
  },
  {
    "order_status_id": 3,
    "name": "Готовится",
    "code": "cooking"
  },
  {
    "order_status_id": 4,
    "name": "Приготовлен",
    "code": "cooking_completed"
  },
  {
    "order_status_id": 5,
    "name": "Доставка",
    "code": "on_way"
  },
  {
    "order_status_id": 6,
    "name": "Доставлен",
    "code": "delivered"
  },
  {
    "order_status_id": 7,
    "name": "Закрыт",
    "code": "closed"
  },
  {
    "order_status_id": 8,
    "name": "Отменен",
    "code": "cancelled"
  }
]

export const stepsUIByStatuses = {
  1: 1,
  2: 2,
  3: 2,
  4: 2,
  5: 3,
  6: 4,
}
