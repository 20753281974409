import React, { useRef, useState, useCallback, useEffect } from 'react'
import clsx from 'clsx'
import IconPlay from '../../img/icons/play.svg'
// import videoSrcWebm from '../../img/onboarding/onboarding-compression.webm'
import videoSrcMp4 from '../../img/onboarding/onboarding-compression.mp4'
import onboardingPoster from '../../img/onboarding/onboarding-poster.png'
import './styles.scss'

const Onboarding = ({
  innerClassName = '',
  title = '',
  autoPlay = false
}) => {
  const [isPlay, setIsPlay] = useState(autoPlay)
  const videoRef = useRef(null)
  const onPlay = useCallback(() => {
    videoRef.current?.play()
    setIsPlay(true)
  }, [])

  const onPause = useCallback(() => {
    videoRef.current?.pause()
    setIsPlay(false)
  }, [])

  const onToggle = useCallback(() => (
    isPlay ? onPause() : onPlay()
  ), [isPlay, onPause, onPlay])

  // useEffect(() => {
  //   if (autoPlay) {
  //     onPlay()
  //   }
  // }, [autoPlay])

  return (
    <div className={clsx('onboarding', innerClassName)} >
      <div className="onboarding__inner">
        {title ? <p className="onboarding__title">{title}</p> : null}
        <div className="onboarding__video-wrap">
          <video
            className="onboarding__video"
            poster={onboardingPoster}
            ref={videoRef}
            muted
            loop
            controls={false}
            autoPlay={autoPlay}
            playsInline
          >
            <source src={videoSrcMp4} type="video/mp4" />
            {/* <source src={videoSrcWebm} type="video/webm" /> */}
          </video>
          <div className="onboarding__play" onClick={onToggle}>
            {!isPlay ? <IconPlay /> : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Onboarding
