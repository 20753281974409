import { roundedNum } from "../../util/number"

export const nonBreakingSpace = '\u00A0'

export const getStatsProduct = (nutrition, quantity = 1, prefix = '') => {
  const { energy_full = 0, proteins_full = 0, fat_full = 0, carbohydrates_full = 0 } = nutrition
  const list = [
    { id: 'energy_full', postfix: 'кКал', value: roundedNum(energy_full * quantity, true), },
    { id: 'proteins_full', postfix: 'г', value: roundedNum(proteins_full * quantity, true), },
    { id: 'fat_full', postfix: 'г', value: roundedNum(fat_full * quantity, true), },
    { id: 'carbohydrates_full', postfix: 'г', value: roundedNum(carbohydrates_full * quantity, true), },
  ]

  return getStrStatsProduct(list, prefix)
}

export const getStrStatsProduct = (data = [], prefix = '') => {
  const list = data.filter(({ value }) => value)
  if (!list.length) {
    return ''
  }

  const str = list.map(({ value, postfix }) => `${value}${nonBreakingSpace}${postfix}`).join(', ')

  return prefix ? `${prefix}${str}` : str
}

export const getAllStatsDetailsProduct = (composition) => {
  const list = [
    // { id: 'weight', name: 'вес', postfix: 'г', active: true },
    { id: 'energy_full', name: 'кКал', postfix: '' },
    { id: 'proteins_full', name: 'белки', postfix: 'г' },
    { id: 'fat_full', name: 'жиры', postfix: 'г' },
    { id: 'carbohydrates_full', name: 'углеводы', postfix: 'г' },
  ]

  const total = {
    weight: 0,
    energy_full: 0,
    proteins_full: 0,
    fat_full: 0,
    carbohydrates_full: 0,
  }

  composition.forEach((item) => {
    total.weight += item.weight * item.quantity
    list.forEach(({ id }) => {
      if (item.nutrition[id]) {
        total[id] += item.nutrition[id] * item.quantity
      }
    })
  })

  const result = []
  const totalList = [
    { id: 'weight', name: 'вес', postfix: 'г', active: true },
    ...list,
  ]

  totalList.forEach(({ id, name, postfix, active }) => {
    if (total[id]) {
      result.push({
        id,
        name,
        value: `${roundedNum(total[id], true)}${nonBreakingSpace}${postfix}`,
        active,
      })
    }
  })

  return result
}

export const getAllStatsOrderProduct = (options) => {
  const list = [
    { id: 'energy_full', name: 'кКал', postfix: 'кКал' },
    { id: 'proteins_full', name: 'белки', postfix: 'г' },
    { id: 'fat_full', name: 'жиры', postfix: 'г' },
    { id: 'carbohydrates_full', name: 'углеводы', postfix: 'г' },
  ]

  const total = {
    weight: 0,
    energy_full: 0,
    proteins_full: 0,
    fat_full: 0,
    carbohydrates_full: 0,
  }

  options.forEach((item) => {
    const count = item.quantity
    if (item.weight) {
      total.weight += item.weight * count
    }
    list.forEach(({ id }) => {
      if (item[id]) {
        total[id] += item[id] * count
      }
    })
  })

  const statsList = []
  list.forEach(({ id, postfix }) => {
    if (total[id]) {
      statsList.push(`${roundedNum(total[id], true)}${nonBreakingSpace}${postfix}`)
    }
  })

  return {
    weight: total.weight ? `${roundedNum(total.weight, true)}${nonBreakingSpace}г` : null,
    stats: statsList.length ? statsList.join(', ') : null,
  }
}
