import React, { useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import API from "../../api";
import backImg1 from '../../img/slider/2x/bg1.jpg'
import backImg2 from '../../img/slider/2x/bg2.jpg'
import backImg3 from '../../img/slider/2x/bg3.jpg'
import backImg4 from '../../img/slider/2x/bg4.jpg'
import backImg5 from '../../img/slider/2x/bg5.jpg'
import backImg6 from '../../img/slider/2x/bg6.jpg'
import contentImg1 from '../../img/slider/2x/main1.png'
import contentImg2 from '../../img/slider/2x/main2.png'
import contentImg3 from '../../img/slider/2x/main3.png'
import contentImg4 from '../../img/slider/2x/main4.png'
import contentImg5 from '../../img/slider/2x/main5.png'
import contentImg6 from '../../img/slider/2x/main6.png'
import IconArrow from '../../img/icons/slider-arrow.svg'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './main.scss'

const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: false,
    slidesToShow: 1,
    slidesToScroll: 1,
};

const list = [
    { id: 1, backImg: backImg1, contentImg: contentImg1 },
    { id: 2, backImg: backImg2, contentImg: contentImg2 },
    { id: 3, backImg: backImg3, contentImg: contentImg3 },
    { id: 4, backImg: backImg4, contentImg: contentImg4 },
    { id: 5, backImg: backImg5, contentImg: contentImg5 },
    { id: 6, backImg: backImg6, contentImg: contentImg6 },
]

const Main = (props) => {
    const sliderRef = useRef(null);
    const onNext = useCallback(() => sliderRef.current?.slickNext(), [])
    const onPrev = useCallback(() => sliderRef.current?.slickPrev(), [])

    return (
        <div className="main-page">
            <div className="main-page__controls">
                <div className="main-page__controls-inner">
                    <div className="slider-control slider-control--prev" onClick={onPrev}><IconArrow /></div>
                    <div className="slider-control slider-control--next" onClick={onNext}><IconArrow /></div>
                </div>
            </div>
            <div className="container">
                <Slider className="slick-fon" {...settings} ref={sliderRef}>
                    {list.map(({ id, backImg, contentImg }) => (
                        <div className="slick-fon-item" key={id}>
                        <img src={backImg} alt="" className="backImg" />
                        <div className="contentWrap">
                            <div className="contentBlock">
                            <img src={contentImg} alt="" className="contentImg" />
                            </div>
                        </div>
                        </div>
                    ))}
                </Slider>
                {/*<div className="play">*/}
                {/*    <svg width="39" height="45" viewBox="0 0 39 45" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                {/*        <path d="M37 19.2229C39.6667 20.7625 39.6667 24.6115 37 26.1511L6.25 43.9046C3.58334 45.4442 0.249998 43.5197 0.249998 40.4405L0.25 4.93349C0.25 1.85429 3.58333 -0.0702136 6.25 1.46939L37 19.2229Z" fill="#6137AE"/>*/}
                {/*    </svg>*/}
                {/*</div>*/}
                <div className='main-page__bottom'>
                {/* <Link
                    to={{
                    pathname: '/Map',
                    state: { fromMain: true }
                    }}
                    className="btn btn-primary"
                >
                    Выбрать адрес доставки
                </Link> */}
                <div className="btn btn-primary" onClick={() => API.temp()}>В меню</div>
                <Link to="/Login" className="btn btn-white">Войти</Link>
                </div>
            </div>
        </div>
    )
}

export default Main
