import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import Modal from '../modal'
import Input from '../input/index'
import { editInfoUser } from '../../store/actions/user'
import { checkEmail } from '../../util/email'

const ModalEmail = ({
  visible = false,
  onClose = () => {},
  value = ''
}) => {
  const dispatch = useDispatch()
  const [localValue, setLocalValue] = useState({ value: value, error: '' })

  const onChangeValue = useCallback((e) => {
    const value = e.target.value
    setLocalValue({ value, error: '' })
  }, [dispatch])

  const onSaveValue = useCallback(async (e) => {
    e?.preventDefault()
    const isValid = checkEmail(localValue.value)
    if (!isValid) {
      setLocalValue({ ...localValue, error: 'Введите корректный email' })
      return
    }

    try {
      const res = await dispatch(editInfoUser({ email: localValue.value })).unwrap()
      if (!res.message) {
        onClose()
      }
    } catch (error) {
      console.log(error)
    }
  }, [dispatch, localValue, onClose])

  const onCloseModal = useCallback(() => {
    setLocalValue({ value, error: '' })
    onClose()
  }, [dispatch, value, onClose])

  return (
    <Modal
      visible={visible}
      onClose={onCloseModal}
      title="Электронный адрес"
      mod="cart"
      onSubmit={onSaveValue}
      btn={{ text: 'Сохранить', onClick: onSaveValue }}
    >
      <Input
        autoFocus
        type="email"
        onChange={onChangeValue}
        value={localValue.value}
        placeholder="Введите email для отправки чека"
        id="email"
        error={localValue.error}
      />
    </Modal>
  )
}

export default ModalEmail
