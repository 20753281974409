import React from 'react'
import emptyFallBackImg from '../../img/empty-image.png'
import './styles.scss'

const Image = ({
  src = '',
  emptyImg,
  ...props
}) => {
  if (src) {
    return <img src={src} alt="" {...props} />
  }

  return <img src={emptyImg || emptyFallBackImg} alt="" {...props} />
}


export default Image
