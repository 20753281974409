import { SIZE_DEFAULT } from "../../constants/product"
import { SERVE_HOT_ID } from "../../util/product/wishes"
import { getStatsProduct } from "../../util/product/stats"
import { roundedNum } from "../../util/number"

const getOptionByBasics = (activeBase, activeSize) => {
  const result = {}
  Object.entries(activeBase).forEach(([option_id, { option_value_id, price }]) => {
    result[option_id] = [{
      option_value_id,
      option_id,
      price,
      quantity: activeSize.coefficient
    }]
  })

  return result
}
const getSingleOptionByList = (active = null, options) => {
  if (!active || !options) {
    return {}
  }

  const option_id = options.option_id
  return {
    [option_id]: [{
      option_id,
      option_value_id: active.option_value_id,
      price: active.price,
      quantity: 1,
    }]
  }
}

const getMultipleOptionsByList = (activeList = [], options) => {
  if (activeList.length === 0 || !options) {
    return {}
  }

  const option_id = options.option_id
  const result = activeList.map((item) => ({
    option_id,
    option_value_id: item.option_value_id,
    price: item.price,
    quantity: 1,
  }))


  return {
    [option_id]: result
  }
}

export const getAllOptions = ({
  product,
  options,
  activeBase,
  activeOptions,
}) => {
  const {
    cookTypes: activeCookTypes = null,
    wishes: activeWish = null,
    serving: activeServing = null,
    taste: activeTaste = [],
    size: activeSize,
  } = activeOptions
  const result = {
    ...options,
    ...getOptionByBasics(activeBase, activeSize),
    ...getSingleOptionByList(activeCookTypes, product.cooktypes),
    ...getMultipleOptionsByList([...activeTaste, activeServing, activeWish].filter((v) => v), product.wishes)
  }

  return result
}

export const getDefaultOptions = (product, queryGroups = []) => {
  const defaultOptions = {}

  const isHasCustomOptions = JSON.stringify(queryGroups) !== '{}'

  product.ingredients.forEach(({ option_id, items }) => {
    const list = []
    const selectedGroup = queryGroups[option_id] || []

    items.forEach(({ default_count, option_value_id, price, quantity }) => {
      const selectedItem = selectedGroup.find(({ option_value_id: id }) => id === option_value_id) || null

      // Если есть выбранный модификатор в урле и хватает остатков, то добавляем
      if (selectedItem && selectedItem.quantity <= quantity) {
        list.push({
          option_id,
          option_value_id,
          price,
          quantity: Math.min(selectedItem.quantity, 10),
        })
        return
      }

      // Если были выбранные параметры в урле, то не берем дефолтные
      if (isHasCustomOptions) {
        return
      }

      // Если дефолтный модификатор, и есть доступное кол-во, то предвыбираем
      if (default_count > 0 && default_count <= quantity) {
        list.push({
          option_id,
          option_value_id,
          price,
          quantity: default_count,
        })
      }
    })

    if (list.length) {
      defaultOptions[option_id] = list
    }
  })

  return defaultOptions
}

export const getDefaultActiveBase = ({ basics = [] }) => {
  if (!basics.length) {
    return {}
  }

  const defaultActiveBase = {}
  basics.forEach(({ option_id, items }, index) => {
    const defaultBasic = items.find(({ default_count }) => default_count > 0)
    if (defaultBasic) {
      defaultActiveBase[option_id] = defaultBasic
    }
  })

  return defaultActiveBase
}

export const getDefaultActiveBaseBySize = (activeBase, basics, size, queryGroups = []) => {
  if (!basics.length) {
    return {}
  }

  const newActiveBase = { ...activeBase }
  basics.forEach(({ option_id, items }) => {
    const selectedGroup = queryGroups[option_id] || []
    // Ищем основу из урла в списке основ одной группы
    const selectedItem = selectedGroup.length ? items.find(({ option_value_id }) => (
      selectedGroup.find(({ option_value_id: id }) => id === option_value_id)
    )) || null : null

    // Если основа нашлась, и хватает кол-ва - оставляем
    if (selectedItem && size.coefficient <= selectedItem.quantity) {
      newActiveBase[option_id] = selectedItem
      return
    }

    // Если основа уже выбрана, и хватает кол-ва - оставляем
    if (newActiveBase[option_id] && size.coefficient <= newActiveBase[option_id].quantity) {
      return
    }

    // Иначе удаляем выбранную основу
    delete newActiveBase[option_id]

    // Выбираем либо дефолтную, подходящую по кол-ву, либо любую первую подходящую по кол-ву
    const defaultBasic = items.find(({ default_count, quantity }) => default_count > 0 && size.coefficient <= quantity)
      || items.find(({ quantity }) => size.coefficient <= quantity)
    if (defaultBasic) {
      newActiveBase[option_id] = defaultBasic
    }
  })

  return newActiveBase
}

const _addWeight = (weight) => weight ? `, ${roundedNum(weight, true)} г` : ''


export const getActiveBasesStringList = (activeBase, activeSize) => (
  Object.entries(activeBase).map(([_, item]) => {
    const quantity = 1 * activeSize.coefficient
    return {
      label: `${item.name} – x${quantity}`,
      // label: `${item.name} – x${quantity}${_addWeight(item.weight * quantity)}`,
      stats: getStatsProduct(item.nutrition, quantity, ' | '),
      weight: item.weight,
      nutrition: item.nutrition,
      quantity,
      name: item.name,
      id: item.option_value_id,
    }
  })
)

export const getActiveModifiersStringList = (options, allIngredients) => {
  const result = []
  Object.entries(options).forEach(([key, items]) => {
    const initialItems = allIngredients.find(({ option_id }) => `${option_id}` === `${key}`)?.items
    if (!initialItems) {
      return
    }

    items.forEach(({ option_value_id, quantity }) => {
      const initialItem = initialItems.find(({ option_value_id: idx }) => idx === option_value_id) || null
      if (!initialItem?.name) {
        return
      }
      result.push({
        label: `${initialItem.name} – x${quantity}`,
        // label: `${initialItem.name} – x${quantity}${_addWeight(initialItem.weight * quantity)}`,
        stats: getStatsProduct(initialItem.nutrition, quantity, ' | '),
        weight: initialItem.weight,
        nutrition: initialItem.nutrition,
        quantity,
        name: initialItem.name,
        id: initialItem.option_value_id,
      })
    })
  })

  return result
}

const getSelectedStateOption = (items, queryGroups, option_id, isSingleItem = true) => {
  const selectedGroup = queryGroups[option_id] || []
  if (!selectedGroup.length) {
    return null
  }

  if (isSingleItem) {
    return (
      items.find(({ option_value_id }) => selectedGroup.some(({ option_value_id: id }) => option_value_id === id)) || null
    )
  }

  const filteredList = items.filter(({ option_value_id }) => selectedGroup.some(({ option_value_id: id }) => option_value_id === id))
  return filteredList.length > 0 ? filteredList : null
}

export const getDefaultStateOptions = (cookTypesList, lists, queryGroups = [], size) => {
  const SERVE_HOT = lists.serving.items.find(({ code }) => code === SERVE_HOT_ID) || null

  const cookTypes = cookTypesList?.items ? (getSelectedStateOption(cookTypesList.items, queryGroups, cookTypesList.option_id) || cookTypesList.items.find(({ default_count }) => default_count > 0) || null) : null
  const wishes = getSelectedStateOption(lists.wishes.items, queryGroups, lists.wishes.option_id) || lists.wishes.items.find(({ default_count }) => default_count > 0) || null
  const serving = getSelectedStateOption(lists.serving.items, queryGroups, lists.serving.option_id) || lists.serving.items.find(({ default_count }) => default_count > 0) || null
  const taste = getSelectedStateOption(lists.taste.items, queryGroups, lists.taste.option_id, false) || lists.taste.items.filter(({ default_count }) => default_count > 0) || []
  return {
    size: size || SIZE_DEFAULT,
    cookTypes,
    wishes,
    serving: cookTypes ? SERVE_HOT : serving,
    taste,
  }
}
