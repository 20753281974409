import React from 'react'
import clsx from 'clsx'
import './styles.scss'

const SoldLabel = ({ mods, title = 'Раскупили' }) => {

  return (
    <div className={clsx('sold-label', mods)} dangerouslySetInnerHTML={{ __html: title }} />
  )
}

export default SoldLabel
