class StickyFilters {
  constructor({ el }) {
    this.el = el
    this.lastScrollTop = 0;
    this.classes = {
      stickyTop: 'is-sticky-top',
      stickyBottom: 'is-sticky-bottom',
      fixedBottom: 'is-fixed-bottom',
      fixedTop: 'is-fixed-top',
      static: 'is-relative',
    }

    this._addClasses = this._addClasses.bind(this)
    this._stickySmall = this._stickySmall.bind(this)
    this._stickyBefore = this._stickyBefore.bind(this)
    this._stickyAfter = this._stickyAfter.bind(this)

    this._stickyWithDirectionDown = this._stickyWithDirectionDown.bind(this)
    this._stickyWithDirectionUp = this._stickyWithDirectionUp.bind(this)

    this.changeSticky = this.changeSticky.bind(this)
    this._resize = this._resize.bind(this)
  }

  _addClasses(className) {
    const delClassNames = Object.values(this.classes).filter(
      (val) => val !== className
    );
    if (className) {
      this.el.classList.add(className);
    }
    this.el.classList.remove(...delClassNames);
  }

  _stickySmall() {
    this._addClasses(this.classes.stickyTop);
  }

  _stickyBefore() {
    this._addClasses(null);
  }

  _stickyAfter() {
    this._addClasses(this.classes.stickyBottom);
  }

  _stickyWithDirectionDown(rect, rectParent, heightWin) {
    if (
      !this.el.classList.contains(this.classes.static) &&
      !this.el.classList.contains(this.classes.fixedBottom)
    ) {
      const diff = -1 * rectParent.top;
      this.el.style.top = `${diff}px`;
      this._addClasses(this.classes.static);
      return;
    }
    if (
      this.el.classList.contains(this.classes.static) &&
      rect.bottom - heightWin <= 0
    ) {
      this._addClasses(this.classes.fixedBottom);
      return;
    }
  }

  _stickyWithDirectionUp(rect, rectParent, heightWin) {
    if (
      !this.el.classList.contains(this.classes.static) &&
      !this.el.classList.contains(this.classes.fixedTop)
    ) {
      const diff = -1 * rectParent.top + (heightWin - rect.height);
      this.el.style.top = `${diff}px`;
      this._addClasses(this.classes.static);
      return;
    }

    if (this.el.classList.contains(this.classes.static) && rect.top >= 0) {
      this._addClasses(this.classes.fixedTop);
      return;
    }
  }

  _sticky() {
    const rect = this.el.getBoundingClientRect();
    const rectParent = this.el.parentNode.getBoundingClientRect();
    const heightWin =
      window.innerHeight || document.documentElement.clientHeight;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const isDirectionDown = scrollTop > this.lastScrollTop;
    this.lastScrollTop = scrollTop;

    // Частный случай, когда фильтры помещаются на экран
    if (rect.height <= heightWin) {
      this._stickySmall()
      return;
    }

    this.el.classList.remove(this.classes.stickyTop);

    const isBefore = rectParent.top >= 0;
    const isAfter = rectParent.bottom - heightWin <= 0;

    // Промежуток до фильтров
    if (isBefore) {
      this._stickyBefore()
      return;
    }

    // Промежуток после фильтров
    if (isAfter) {
      this._stickyAfter()
      return;
    }

    // Основной промежуток
    if (isDirectionDown) {
      // Направление Вниз
      this._stickyWithDirectionDown(rect, rectParent, heightWin)
    }
    if (!isDirectionDown) {
      // Направление Вверх
      this._stickyWithDirectionUp(rect, rectParent, heightWin)
    }
  }

  changeSticky() {
    this._sticky()
  }

  _resize() {

  }

  init() {
    this._resize()
    this._sticky()
    window.addEventListener('scroll', () => this._sticky())
    window.addEventListener('resize', () => this._resize())
  }

  destroy() {
    window.removeEventListener('scroll', () => this._sticky())
    window.removeEventListener('resize', () => this._resize())
  }
}

export default StickyFilters
