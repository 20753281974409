import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import progressImg from '../img/progress1.png'
import { getLastOrder } from '../query/orders'


const StatusOrder = () => {
  const [lastOrder, setLastOrder] = useState(false)
  useEffect(() => {
    getLastOrder(setLastOrder)
  }, [])

  if (!lastOrder || lastOrder.deliveryDate) {
    return null
  }

  return (
    <Link to="/Orders" className="status-order">
      <img src={progressImg} alt=""/>
      <span>Заказ {lastOrder.orderId}. {lastOrder.status}</span>
    </Link>
  )
}

export default StatusOrder
