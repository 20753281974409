import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import IconLocation from '../../img/icons/cart/location.svg'
import IconMessage from '../../img/icons/cart/message.svg'
import IconTime from '../../img/icons/cart/time.svg'
import IconCoupon from '../../img/icons/cart/coupon.svg'
import IconCouponActive from '../../img/icons/cart/coupon-active.svg'
import IconMoney from '../../img/icons/cart/money.svg'
import IconEmail from '../../img/icons/cart/email.svg'
import './styles.scss'

const ICONS = {
  location: IconLocation,
  message: IconMessage,
  time: IconTime,
  coupon: IconCoupon,
  'coupon-active': IconCouponActive,
  money: IconMoney,
  email: IconEmail,
}

const CartLink = ({
  to = '',
  onClick = () => {},
  text = '',
  note = '',
  icon = '',
  image = '',
  mod = null,
  disabled = false
}) => {
  const iconRender = useMemo(() => {
    if (image) {
      return <img src={image} alt="" />
    }

    if (icon && ICONS[icon]) {
      const Icon = ICONS[icon]
      return <Icon />
    }

    return null
  }, [image, icon])

  const content = (
    <>
      {iconRender ? <div className="cart-link__icon">{iconRender}</div> : null}
      <div className="cart-link__content">
        <p className="cart-link__text">{text}</p>
        {note ? (
          <p className="cart-link__note">{note}</p>
        ) : null}
      </div>
    </>
  )

  if (disabled) {
    return (
      <div className={clsx('cart-link', 'cart-link--disabled', mod)}>{content}</div>
    )
  }

  if (to) {
    return (
      <Link to={to} className={clsx('cart-link', mod)} onClick={onClick}>{content}</Link>
    )
  }

  return (
    <div className={clsx('cart-link', mod)} onClick={onClick}>{content}</div>
  )
}

export default CartLink
