import React, {useMemo, useState, useEffect, useCallback, useRef} from 'react';
import {Link, useHistory, useLocation} from "react-router-dom";
import {YMaps, Map, withYMaps, Polygon} from "react-yandex-maps";
import _debounce from 'lodash/debounce';
import { useSelector } from "react-redux";
import { isAuthSelector } from "../../store/selectors/user";
import Input from "../../components/input/index";
import ModalDeliveryAddress from "../../components/modal-delivery-address";
import TopPage from "../../components/top-page";
import { QUERY_MAP } from "../../constants/map";
import { bindAddress, validateWithHint } from "../../util/address";
import { useReducer } from "../../util/store";
import useLayout from "../../util/layout";
import { saveAddress, getAddress, defaultAddress } from "../../query/address";
import DeleteImg from "../../img/delete-img.svg";
import PrevSvg from "../../img/prevSvg.svg";
import polygonData, { options as optionsPolygon } from '../../constants/polygon'
import { checkPointInPolygons } from '../../util/map'
import "./styles.scss";

const [alert, setAlert] = useReducer('alert');

const defaultStateMap = { center: [defaultAddress.coordinates.lat, defaultAddress.coordinates.lon], zoom: 9 }

const polygons = [{
    id: 'main',
    coordinates: polygonData
}]

const regexp = /\d+/;
const joinList = (list) => list.filter((v) => v).join(', ')
const transformSuggest = (items = []) => (
    items.map((item) => {
        const { value, displayName } = item
        const a = displayName.split(',');
        const d = value.split(',');
        const house = value.match(regexp) !== null ? a[1] : '';

        return {
            ...item,
            renderAddress: joinList([a[0], house]),
            renderCountry: joinList([d[0], d[1], d[2]]),
        }
    })
)

const MapSuggestComponent = ({
    ymaps,
    onShowModalDeliveryAddress,
    setBaseAddress,
    isAnonymous = false,
}) => {
    const history = useHistory()
    const [search, setSearch] = useState('')
    const [suggests, setSuggests] = useState([])
    const [initialCoordinates, setInitialCoordinates] = useState([
        defaultAddress.coordinates.lat, defaultAddress.coordinates.lon
    ]);
    const mapRef = useRef(null)

    const initMap = useCallback((map) => {
        if(map) {
            mapRef.current = map
        }
    }, [])

    const polygonsRef = useRef([])
    const setInstanceRef = useCallback((index, ref) => {
        polygonsRef.current[index] = ref
    }, [])

    useEffect(() => {
        // Пропускаем
        if (isAnonymous) {
            return
        }

        // Для авторизованных юзеров устанавливаем базовый адрес
        getAddress((address) => {
            if (address.coordinates) {
                setInitialCoordinates([address.coordinates.lat, address.coordinates.lon])
            }
            if (address.street) {
                setSearch(`${address.street}, ${address.house}`);
            }
        });
    }, [isAnonymous]);

    function searchMapClick(e){
        e.preventDefault();
        setSearch("")
    }

    const getSuggests = useCallback((val) => {
        const map = ymaps
        if (map) {
            map.suggest(`Москва, ${val}`).then(function (items) {
                setSuggests(transformSuggest(items))
            }, (error) => {
                console.log('error', error)
            });
        }
    }, [ymaps])

    const debounceGetSuggests = useCallback(_debounce(getSuggests, 300), []);

    const onChange = useCallback((e) => {
        const val = e.target.value
        setSearch(val)
        debounceGetSuggests(val)
    }, [getSuggests])

    const onClickSuggest = useCallback(({ displayName, value }) => {
        if (value.match(regexp) === null) {
            const street = displayName.split(',');
            const value =  street[0] + ', '
            setSearch(value)
            debounceGetSuggests(value)
            return;
        }

        ymaps.geocode(value).then(function(res) {
            let geoObject = res.geoObjects.get(0);
            const validInfo = validateWithHint(geoObject)
            if(validInfo.isError){
                setAlert({ danger: validInfo.hint });
            } else {
                const address = bindAddress(geoObject)

                // Проверяем вхождение в зону доставки
                const isContains = checkPointInPolygons(ymaps, mapRef.current, polygonsRef.current, [address.coordinates.lat, address.coordinates.lon])

                if (!isContains) {
                    setAlert({ danger: 'Выбранный адрес не входит в зону доставки' })
                    return
                }

                // Если координаты изменились обнуляем остальные поля адреса
                const newAddress = initialCoordinates.toString() === [address.coordinates.lat, address.coordinates.lon].toString() ? (
                    address
                ) : ({
                    ...address,
                    apartment: '',
                    floor: '',
                    entrance: '',
                    code: '',
                    comment: '',
                })

                // Для анонимных юзеров сохраняем адрес локально и показываем модалку
                if (isAnonymous) {
                    setBaseAddress(newAddress)
                    onShowModalDeliveryAddress()
                    return
                }

                // Для авторизованных
                saveAddress(newAddress, (res) => {
                    setInitialCoordinates([
                        res.coordinates.lat, res.coordinates.lon
                    ]);
                    onShowModalDeliveryAddress()
                });
            }
        });
    }, [history, onShowModalDeliveryAddress, initialCoordinates, isAnonymous, ymaps])

    return (
        <div className="address-page__body">
            <div className="address-page__field">
                <Input
                    type="text"
                    onChange={onChange}
                    value={search}
                    placeholder="Введите улицу и номер дома:"
                    label="Введите адрес"
                    id="address"
                />
            </div>
            {suggests.length ? (
                <div className="address-dropdown address-page__suggests">
                    {suggests.map((item, index) => (
                        <div className="address-dropdown__item" key={`${item.value}__${index}`} onClick={() => onClickSuggest(item)}>
                            <div className="address-dropdown__item-name">{item.renderAddress}</div>
                            <div className="address-dropdown__item-desc">{item.renderCountry}</div>
                        </div>
                    ))}
                </div>
            ) : null}
            <div style={{ opacity: 0 }}>
                <Map
                    instanceRef={initMap}
                    width="100%"
                    height="100%"
                    defaultState={defaultStateMap}
                    options={{ suppressMapOpenBlock: true }}
                >
                    {polygons.map(({ id, coordinates }, index) => (
                        <Polygon
                            instanceRef={(value) => setInstanceRef(index, value)}
                            key={id}
                            geometry={coordinates}
                            options={optionsPolygon}
                        />
                    ))}
                </Map>
            </div>
        </div>
    )
  }

const Address = () => {
    useLayout()
    const history = useHistory()
    const location = useLocation()
    const fromMain = location?.state?.fromMain
    const fromParentLocation = location?.state?.from
    const isAuth = useSelector(isAuthSelector)
    const [baseAddress, setBaseAddress] = useState(defaultAddress)
    const [modalDeliveryAddress, setModalDeliveryAddress] = useState(false)
    const onCloseModalDeliveryAddress = useCallback(() => setModalDeliveryAddress(false), [])
    const onShowModalDeliveryAddress = useCallback(() => setModalDeliveryAddress(true), [])
    const SuggestComponent = useMemo(() => {
        return withYMaps(MapSuggestComponent, true, [
          'SuggestView',
          'geocode',
          'coordSystem.geo',
          'geoQuery',
          'GeoObject'
        ]);
      }, []);

    return (
        <div className="address-page layout">
            <div className="layout__top layout__top--gray">
                <TopPage
                    onClick={history.goBack}
                    title="Адрес доставки"
                />
            </div>
            <div className="layout__body">
                <div className="grid-inner">
                    <YMaps query={QUERY_MAP}>
                        <SuggestComponent
                            onShowModalDeliveryAddress={onShowModalDeliveryAddress}
                            setBaseAddress={setBaseAddress}
                            isAnonymous={!isAuth}
                        />
                    </YMaps>
                </div>
            </div>
            <ModalDeliveryAddress
                fromLocation="Address"
                fromMain={fromMain}
                fromParentLocation={fromParentLocation}
                visible={modalDeliveryAddress}
                onClose={onCloseModalDeliveryAddress}
                baseAddress={baseAddress}
                isAnonymous={!isAuth}
            />
        </div>
    );
};

export default Address;
