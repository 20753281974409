const getNameGroupBasic = (name = '') => {
  try {
    const list = name.split('!')
    return list[list.length - 1]
  } catch (error) {}

  return name
}

const getNameBasic = (groupName, originName = '') => {
  const isNotSelected = originName.toLowerCase() === 'не выбрано'
  if (isNotSelected) {
    return { name: `${groupName}: ${originName}`, originName, groupName, isNotSelected }
  }

  return { name: originName, originName, groupName, isNotSelected: false }
}

const transformBasics = (basics = []) => (
  basics.map((group) => {
    const name = getNameGroupBasic(group.name)
    return {
      ...group,
      name,
      items: group.items.map((item) => ({
        ...item,
        ...getNameBasic(name, item.name),
      }))
    }
  })
)

const transformIngredients = (ingredients = []) => (
  ingredients.map((group) => ({
    ...group,
    items: group.items.map((item) => ({
      ...item,
      option_id: group.option_id,
      free_count: group.free_count,
    }))
  }))
)

const transformProduct = (product) => ({
  ...product,
  basics: transformBasics(product.basics),
  // Дублирую поля группы в items - необходимо для правильной работы блока Выбранные
  ingredients: transformIngredients(product.ingredients),
})

export default transformProduct
