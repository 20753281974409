import React, {useCallback, useEffect, useState} from 'react'
import clsx from 'clsx'
import API from '../../api'
import Modal from '../modal'
import './styles.scss'

const CartPayment = ({
  visible = false,
  onClose = () => {},
  setCartData,
  payment = null,
}) => {
  const [payments, setPayments] = useState([])

  useEffect(() => {
    API.get(
      '/payments',
      (response) => {
        if(response.payments) setPayments(response.payments);
      },
      (error) => console.log(error),
      { useCache: true },
    )
  }, [])

  const onChange = useCallback(async (payment) => {
    try {
      await setCartData({payment: payment});
      onClose()
    } catch (error) {
      console.log(error)
    }
  }, [setCartData, onClose])

  return (
    <Modal
      visible={visible}
      onClose={onClose}
      title="Способ оплаты"
      mod="cart"
    >
      <div className="cart-payment">
        {payments.map((item) => (
          <div
            key={item.code}
            className={clsx('cart-payment__item', payment?.code === item.code && 'active')}
            onClick={() => onChange(item.code)}
          >
              <div className="cart-payment__item-icon">
                <img src={item.image} alt="" />
              </div>
              <p className="cart-payment__item-name">{item.name}</p>
          </div>
        ))}
      </div>
    </Modal>
  )
}

export default CartPayment
