export const bindAddress = (geoObject) => {
  let _address = geoObject.getThoroughfare();

  if (!_address) {
      let loc = geoObject.getLocalities();
      _address = loc[1];
      if (loc[2]) {
          _address = loc[2];
      }
  }

  let coord = geoObject.geometry.getCoordinates();
  return {
      city: geoObject.getLocalities()[0],
      coordinates: {lat: coord[0], lon: coord[1]},
      street: _address,
      house: geoObject.getPremiseNumber()
  }
}


export const validate = (geoObject) => {
  let error = '';
  let hint = '';
  if (geoObject) {
      switch (geoObject.properties.get('metaDataProperty.GeocoderMetaData.precision')) {
          case 'exact':
              break;
          case 'number':
          case 'near':
          case 'range':
              error = 'Неточный адрес, требуется уточнение';
              hint = 'Уточните номер дома';
              break;
          case 'street':
              error = 'Неполный адрес, требуется уточнение';
              hint = 'Уточните номер дома';
              break;
          case 'other':
          default:
              error = 'Неточный адрес, требуется уточнение';
              hint = 'Уточните адрес';
      }
  } else {
      error = 'Адрес не найден';
      hint = 'Уточните адрес';
  }

  if (error) {
      return false;
  } else {
      return true;
  }
}
export const validateWithHint = (geoObject) => {
  let error = '';
  let hint = '';
  if (geoObject) {
      switch (geoObject.properties.get('metaDataProperty.GeocoderMetaData.precision')) {
          case 'exact':
              break;
          case 'number':
          case 'near':
          case 'range':
              error = 'Неточный адрес, требуется уточнение';
              hint = 'Уточните номер дома';
              break;
          case 'street':
              error = 'Неполный адрес, требуется уточнение';
              hint = 'Уточните номер дома';
              break;
          case 'other':
          default:
              error = 'Неточный адрес, требуется уточнение';
              hint = 'Уточните адрес';
      }
  } else {
      error = 'Адрес не найден';
      hint = 'Уточните адрес';
  }

  return { isError: !!error, error, hint };
}
