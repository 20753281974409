import React from 'react'
import clsx from 'clsx'
import './styles.scss'

const HeaderBtn = ({
  children,
  active = false,
  count = 0,
  onClick = () => {},
}) => {
  return (
    <div className={clsx('header-btn', active && 'active')} onClick={onClick}>
      {children}
      {count ? (
        <span className="header-btn__count">
          <span>{count > 9 ? '9+' : count}</span>
        </span>
      ) : null}
    </div>
  )
}

export default HeaderBtn
