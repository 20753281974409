import React, { useCallback, useMemo } from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import Image from '../image'
import { getBackgroundColor } from '../../util/catalog/color'
import getTextCategory from '../../util/catalog/text'
import emptyImg from '../../img/empty/catalog.png'

import './styles.scss'

const CatalogItem = ({ data, base: baseUrl = '', onShowChat }) => {
  const isDefault = useMemo(() => !!data.default, [data.default])
  const isChat = useMemo(() => data.id === 'chat', [data.id])
  const link = useMemo(() => {
    const base = data.type === 'category' ?  `${baseUrl}/` : '/Subcategory/'
    return `${base}${data.alias}`
  }, [data])

  const styles = useMemo(() => {
    if (isChat) {
      return { backgroundColor: getBackgroundColor(data) }
    }

    const image = (!data.default && data.image) ? data.image : emptyImg
    return {
      backgroundImage: `url(${image})`,
      backgroundColor: getBackgroundColor(data),
    }
  }, [data.image])

  const textNote = useMemo(() => {
    const text = getTextCategory(data.alias)
    if (!text) {
      return null
    }

    return <><br/><span className="catalog-card__note">{text}</span></>
  }, [data.alias])

  const onClick = useCallback(() => {
    if (isChat && onShowChat) {
      onShowChat()
    }
  }, [isChat, onShowChat])

  const linkBlock = data.disabled ? (
    <div className={clsx('catalog-card__link', isChat && 'catalog-card--chat')} style={styles} onClick={onClick}>
      <p className={clsx('catalog-card__name', !isChat && 'catalog-card__name--disabled')}>
        {data.name} <br />
        <span className="catalog-card__note">{isChat ? 'Просто напишите и мы приготовим' : 'Скоро'}</span>
      </p>
    </div>
  ) : (
    isDefault ? (
      <div className={clsx('catalog-card__link')} style={styles}>
        <p className="catalog-card__name">{data.name}</p>
      </div>
    ) : (
      <Link className={clsx('catalog-card__link')} to={link} style={styles}>
        <p className={clsx('catalog-card__name', textNote && 'catalog-card__name--has-notes')}>{data.name}{textNote}</p>
      </Link>
    )
  )

  return (
    <div className={clsx('catalog-card', isDefault && 'loadData')}>
      <div className="catalog-card__inner">{linkBlock}</div>
    </div>
  )
}

export default CatalogItem
