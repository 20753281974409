import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import Icon from '../../img/icons/prev-arrow.svg'
import './styles.scss'

const BtnBack = ({
  to,
  onClick = () => {},
  text = '',
  mod
}) => {
  const content = useMemo(() => (
    <>
      <span className="btn-back__icon"><Icon /></span>
      {text ? <span className="btn-back__text">{text}</span> : null}
    </>
  ), [text])

  if (to) {
    return (
      <Link className={clsx('btn-back', mod)} to={to} onClick={onClick}>
        {content}
      </Link>
    )
  }

  return (
    <div className={clsx('btn-back', mod)} onClick={onClick}>
      {content}
    </div>
  )

}

export default BtnBack
