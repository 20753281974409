import React, { useState, useEffect, useMemo } from 'react'
import { useLocation, Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'react-bootstrap'
import clsx from 'clsx'
import Header from '../../components/header'
import useBodyMod from '../../util/body-mod'
import useLayout from '../../util/layout'
import './styles.scss'


const Thanks = () => {
  useLayout()
  useBodyMod('body-white')

  return (
    <div className="layout">
      <div className="layout__top">
        <Header />
      </div>
      <div className="layout__body closed-kitchen__body">
        <div className="grid-inner">
          <div className="closed-kitchen__content">
            <div className="closed-kitchen__texts">
              <p className="closed-kitchen__subtitle">
                К сожалению, не можем выполнить ваш заказ прямо сейчас - мы работаем над улучшением кухни
              </p>
              <p className="closed-kitchen__subtitle">
                Начислим вам 300 бонусных баллов и ждем вас снова!
              </p>
              <p className="closed-kitchen__subtitle">Наш телеграмм с новостями <a href="https://t.me/tarelkavv" target="_blank">@tarelkavv</a></p>
            </div>
            <Link className="btn btn-primary" to="/Catalog">В каталог</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Thanks
