import React, { useMemo, forwardRef } from 'react'
import InputMask from 'react-input-mask'
import clsx from 'clsx'
import './styles.scss'

const Input  = forwardRef(function Input ({
    isTextarea = false,
    error,
    mod,
    label = '',
    id: idOrigin = '',
    isMask = false,
    ...props
  }, ref) {
    const id = useMemo(() => idOrigin || label, [idOrigin])

    return (
      <div className={clsx('input-block', mod, error && 'isError')}>
        {label ? (
          <label className="input__label" htmlFor={id}>{label}</label>
        ) : null}
        {isTextarea ? (
          <textarea {...props} ref={ref} id={id} className="input input--textarea" />
        ) : (
          isMask ? (
            <InputMask {...props} id={id} className="input" ref={ref} />
          ) : (
            <input {...props} id={id} className="input" ref={ref} />
          )
        )}
        {error ? <span className="input__error">{error}</span> : null}
      </div>
    )
  }
)

export default Input
