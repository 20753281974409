import React from 'react'
import clsx from 'clsx'
import Icon from '../../img/icons/prev-arrow.svg'
import './styles.scss'

const AddressBtn = ({
  onClick = () => {},
  onClickDetails = () => {},
  text = '',
  mod,
}) => {

  return (
    <div className={clsx('address-btn', mod)}>
      <div className="address-btn__content" onClick={onClick}>{text}</div>
      <div className="address-btn__icon" onClick={onClickDetails}><Icon /></div>
    </div>
  )
}

export default AddressBtn
