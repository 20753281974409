import React from 'react'
import { Link } from 'react-router-dom'
import Icon from '../../img/icons/prev-light-arrow.svg'
import './styles.scss'

const TopPage = ({
  to,
  onClick = () => {},
  title = '',
}) => {

  return (
    <div className="grid-inner">
      <div className="top-page">
        <div className="top-page__block">
        {to ? (
          <Link className="top-page__link" to={to} onClick={onClick}><Icon /></Link>
        ) : (
          <div className="top-page__link" onClick={onClick}><Icon /></div>
        )}
        </div>
        <p className="top-page__center">{title}</p>
        <div className="top-page__block" />
      </div>
    </div>
  )
}

export default TopPage
