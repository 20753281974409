const obj = {
  'root': '<strong>Как заказать:</strong> выбирайте блюда от Шефа, собирайте Тарелку конструктор или напишите в Чат - мы приготовим',
  'category': '<strong>Шаг 1</strong><br/>Выберите основу - с чего начать',
  'subcategory': '<strong>Шаг 2</strong><br/> Выберите наполнение - с чем сочетать',
  'product': '<strong>Шаг 3</strong><br/> Настройте пожелания или добавьте в корзину',
}

const getTexts = () => {
  const res = {}

  Object.entries(obj).forEach(([key, value]) => {
    res[key.toLowerCase()] = value
  })

  return res
}

const texts = getTexts()

export const getTextForOnboarding = (key = '') => {
  if (!key) {
    return null
  }

  return texts[key.toLowerCase()] || ''
}
