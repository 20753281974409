export const API_KEY_MAP = (typeof API_KEY_YANDEX_MAP !== 'undefined' ? API_KEY_YANDEX_MAP : '' )
export const API_KEY_SUGGEST = (typeof API_KEY_YANDEX_SUGGEST !== 'undefined' ? API_KEY_YANDEX_SUGGEST : '' )

export const QUERY_MAP = {
    lang: 'ru_RU',
    ns: "use-load-option",
    apikey: API_KEY_MAP,
    suggest_apikey: API_KEY_SUGGEST,
    load: "geocode,geolocation,suggest,geoQuery"
}
