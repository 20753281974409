import React, { useRef, useCallback, useEffect, useState } from 'react'
import './styles.scss'

const Checkbox = ({
  id,
  value,
  onChange,
  children,
}) => {

  return (
    <div className="checkbox__block">
      <label className="checkbox__field">
        <input type="checkbox"  className="checkbox" id={id} checked={value} onChange={onChange}/>
        <span className="checkbox__icon" />
      </label>
      {children ? (
        <label className="checkbox__label" htmlFor={id}>{children}</label>
      ) : null}
    </div>
  )
}

export default Checkbox
