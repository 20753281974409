import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { getArticleById } from '../../query/article'
import TopPage from '../../components/top-page'
import { HeadNested } from '../../components/head'
import useLayout from '../../util/layout'
import './styles.scss'

const defaultList = [1,2,3,4,5,6,7,8,9,10]

const SinglePage = () => {
    useLayout()
    const history = useHistory()
    const {alias} = useParams();
    const [data, setData] = useState({
        description: '',
        information_id: '',
        meta_description: '',
        meta_h1: '',
        meta_keyword: '',
        meta_title: '',
        title: '',
    });
    const [isLoading, setLoading] = useState(true)

    useEffect(()=>{
        setLoading(true)
        getArticleById(alias, (res) => {
            setLoading(false)
            setData(res)
        }, () => history.replace('/404'))
    }, [alias, history]);

    return (

        <div className="text-page layout">
            <HeadNested page="page" name={data.title} />
            <div className="layout__top layout__top--gray">
                <TopPage
                    onClick={history.goBack}
                    title={data.title}
                />
            </div>
            <div className="layout__body">
                <div className="grid-inner">
                    <div className={`text-page__body ${isLoading ? 'loadData' : ''}`}>
                        <div className={`text-page__content article ${isLoading ? 'loadData' : ''}`}>
                            {isLoading ? (
                                defaultList.map((val) => <p key={val} className="text"><br/><br/><br/><br/></p>)
                            ) : <div dangerouslySetInnerHTML={{ __html: data.description }} />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SinglePage;
