import React, { useCallback, useEffect, useState, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import clsx from 'clsx'
import _throttle from 'lodash/throttle'
import IconTopMove from '../../img/icons/icon-top-move.svg'
import { CHANGE_LAYOUT_PADDING_BOTTOM } from '../../constants/events'
import './styles.scss'

const DEFAULT_BOTTOM = 24

const TopMove = () => {
  const location = useLocation()
  const [bottom, setBottom] = useState(DEFAULT_BOTTOM)
  const [show, setShow] = useState(false)
  useEffect(() => {
    const bottom = document.querySelector('.layout__bottom')
    if (!bottom) {
      setBottom(DEFAULT_BOTTOM)
      return
    }

    const heightBottom = bottom.getBoundingClientRect().height
    setBottom(heightBottom + DEFAULT_BOTTOM)
  }, [location.pathname])

  useEffect(() => {
    const toggleBtn = () => {
      // const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      // const scrollHeight = Math.max(
      //   document.body.scrollHeight, document.documentElement.scrollHeight,
      //   document.body.offsetHeight, document.documentElement.offsetHeight,
      //   document.body.clientHeight, document.documentElement.clientHeight
      // )
      if (scrollTop > 80) {
      // if (scrollHeight > height && scrollTop > 80) {
        setShow(true)
      } else {
        setShow(false)
      }
    }

    const setPosition = (e) => {
      const heightBottom =  e.detail.heightBottom
      setBottom(heightBottom + DEFAULT_BOTTOM)
    }

    const toggleBtnThrottle = _throttle(toggleBtn, 100)

    toggleBtn()
    window.addEventListener('scroll', toggleBtnThrottle)
    window.addEventListener('resize', toggleBtnThrottle)
    window.addEventListener(CHANGE_LAYOUT_PADDING_BOTTOM, setPosition)

    return () => {
      window.removeEventListener('scroll', toggleBtnThrottle)
      window.removeEventListener('resize', toggleBtnThrottle)
      window.removeEventListener(CHANGE_LAYOUT_PADDING_BOTTOM, setPosition)
    }
  }, [])

  const onClick = useCallback(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'auto' })
  }, [])

  return (
    <div className="top-move__wrap" style={{ bottom: bottom + 'px' }}>
      <div className="grid-inner">
        <div className="top-move__body">
          <div className={clsx('top-move', show && '_show')} onClick={onClick}><IconTopMove /></div>
        </div>
      </div>
    </div>
  )
}

export default TopMove
