export const SHIPPING_CODES = {
  PICKUP: 'pickup',
  FLAT: 'flat',
}

export const NOW_SHIPPING_FLAT = {
  from: '',
  to: '',
  value: 'Ближайший час',
}

export const NOW_SHIPPING_PICKUP = {
  from: '',
  to: '',
  value: '15 минут',
}

export const getTimeNowByShippingCode = (time, code) => {
  if (!time) {
    return NOW_SHIPPING_FLAT.value
  }

  // Если самовызов, но в качестве временного слота выбран текст от доставки, то возвращаем текст от самовызова
  if (code === SHIPPING_CODES.PICKUP && time === NOW_SHIPPING_FLAT.value) {
    return NOW_SHIPPING_PICKUP.value
  }

  // Если доставка, но в качестве временного слота выбран текст от самовызова, то возвращаем текст от доставки
  if (code === SHIPPING_CODES.FLAT && time === NOW_SHIPPING_PICKUP.value) {
    return NOW_SHIPPING_FLAT.value
  }

  // В противном случае возвращаем выбранный временной слот как есть
  return time
}
