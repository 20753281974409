import API from "../api";

export const getTags = (callback, failCallback = () => {}, config = {}) => {
    API.get(
        '/tags',
        (response)=>{
            if(response){
                callback(response);
            }
        },
        failCallback,
        config,
    );
}

export const setTag = (data, callback, failCallback = () => {}, config = {}) => {
    API.post(
        '/tags',
        data,
        (response)=>{
            if(response){
                callback(response);
            }
        },
        failCallback,
        config,
    );
}

export const setFilter = (data, callback, failCallback = () => {}, config = {}) => {
    API.post(
        '/filter',
        data,
        (response)=>{
            if(response){
                callback(response);
                return
            }
            failCallback()
        },
        function (error) {
            failCallback(error);
        },
        config,
    );
}


export const getGroups = (callback) => {
    API.get(
        '/tagGroups',
        function (response){
            if(response.tagGroups && callback) callback(response.tagGroups);
        }
    );
}

export const getSelected = (callback) => {
    API.get(
        '/tagSelected',
        function (response){
            if(response.tags && callback) callback(response.tags);
        }
    );
}
