import React, { useEffect, useState } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import ErrorBlock from '../../components/error-block'

const defaultInfo = {
  code: '500',
  title: 'Что-то пошло не так',
  description: 'Попробуйте позже'
}

const PageError = () => {
  const [info, setInfo] = useState(defaultInfo)
  const params = useParams()
  const location = useLocation()

  useEffect(() => {
    if (location.search.length === 0) {
      return setInfo(defaultInfo)
    }

    try {
      const params = new URLSearchParams(location.search)
      setInfo({
        code: params.get('code') || defaultInfo.code,
        title: params.get('title') || defaultInfo.title,
        description: params.get('description') || defaultInfo.description,
      })
    } catch (error) {
      console.log(error)
    }
  }, [location.search])

  return (
    <div className="layout">
      <ErrorBlock
        code={info.code}
      >
        {info.title ? <p>{info.title}</p> : null}
        {info.description ? <p>{info.description}</p> : null}
      </ErrorBlock>
    </div>
  )
}

export default PageError
