import React, {useState, useEffect} from "react";

const store = {
    values: {},
    subscribe: {},
    get(key) {
        return [
            this.values[key],
            function (value) {
                store.values[key] = value;
                let object = store.subscribe[key] || {};
                Object.keys(object).map(function(objectKey) {
                    object[objectKey](value);
                });
            }
        ];
    },
    set(key, id, state){
        if (!this.subscribe[key]) this.subscribe[key] = {};
        if(!this.values[key]) this.values[key] = state[0];

        this.subscribe[key][id] = state[1];
    }
}

export function useReducer(key, componentName, defaultState) {
    if(typeof defaultState === 'undefined'){
        return store.get(key);
    }

    let state = [];
    if(componentName){
        state = useState(defaultState);
    }

    let value = (store.values[key] !== undefined ? store.values[key] : defaultState);

    store.set(key, componentName, [value, state[1]]);

    return store.get(key);
}
