import API from "../api";

export const defaultAddress = {
    default: true,
    coordinates: {
        lat: "55.615970",
        lon: "37.613677"
    },
    city: null,
    street: '',
    house: '',
    entrance: '',
    floor: '',
    apartment: '',
    entrance: '',
    code: '',
    comment: ''
}

let address = {
    data: null,
};
export const getAddress = (callback, failCallback = () => {}) => {
    if(address.data){
        callback(address.data);
    }else{
        API.get(
            '/address',
            function (response){
                if(response.street || response.street == null) {
                    address.data = response;
                    if(!address.data.street) {
                        address.data.coordinates = defaultAddress.coordinates;
                        address.data.street = defaultAddress.street;
                    }
                    callback(address.data);
                }
            },
            (err) => failCallback(err)
        )
    }
}

export const clearAddressForNewTempUser = (callback) => {
    address.data = null
}

export const resetAddress = (callback) => {
    address.data = null
}

export const saveAddress = (data, callback) => {
    API.post(
        '/address',
        data,
        function (response){
            if(response.street && callback){
                address.data = response;
                callback(response);
            }
        }
    )
}

// Проверяет текущий адрес и сохраняет новый, если текущий адрес пуст
export const updateAddress = (address, callback, failCallback = () => {}) => {
    API.get(
        '/address',
        function (response){
            if(!response.street || !response.house) {
                saveAddress(address, callback)
                return
            }
            callback()
        },
        (err) => failCallback(err)
    )
}
