import React, { useEffect, useState, useRef, useCallback, useLayoutEffect } from 'react'
import clsx from 'clsx'
import { slideDown, slideUp } from '../../util/collapse/animation'
import './styles.scss'

const Collapse = ({
  type = '',
  title = '',
  subtitle = '',
  note = '',
  bottomTitle = '',
  mod,
  duration = 300,
  defaultOpen = false,
  children,
}) => {
  const timer = useRef(null)
  const isMounted = useRef(false)
  const refBody = useRef(null)
  const refRoot = useRef(null)
  const refContent = useRef(null)
  const [open, setOpen] = useState(defaultOpen)

  const onToggle = useCallback(() => {
    setOpen(openValue => !openValue)
  }, [])

  useEffect(() => {
    if (!refBody.current || !isMounted.current) {
      return
    }

    if (open) {
      slideDown(refBody.current, duration)

      clearTimeout(timer.current)
      timer.current = setTimeout(() => {
        const rect = refRoot.current.getBoundingClientRect()
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop
        window.scrollTo({ top: rect.top + scrollTop, left: 0, behavior: 'auto' })
      }, duration + 10)
    } else {
      slideUp(refBody.current, duration)
    }

    return () => clearTimeout(timer.current)
  }, [open, duration])

  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  const onClickHeader = useCallback(() => {
    onToggle()
  }, [onToggle])

  useEffect(() => {
    if (defaultOpen) {
      setOpen(defaultOpen)
      const el = refBody.current
      if (!el) {
        return
      }
      el.style.height = 'auto'
      el.style.overflow = 'initial'
    }
  }, [defaultOpen])

  return (
    <div className={clsx('collapse-block', mod, open && 'open', type)} ref={refRoot}>
      <div className="collapse-block__header" onClick={onClickHeader}>
        <div className="collapse-block__header-inner">
          <div className="collapse-block__header-text-inner">
            <p className="collapse-block__header-text">
              {title}
              {subtitle ? <span className="collapse-block__header-subtitle">{subtitle}</span> : null}
            </p>
            {note ? (
              <p className="collapse-block__header-note">{note}</p>
            ) : null}
          </div>

          <div className="collapse-block__header-icon" />
        </div>
        {type === 'compound' && bottomTitle ? (
          <div className="collapse-block__header-bottom">
            {!open && bottomTitle ? (
              <div className="collapse-block__header-bottom-compound">{bottomTitle}</div>
            ) : null}
            {open ? (
              <p className="collapse-block__header-bottom-notes">
                Продукт — количество
                <span className="legends">
                  <span className="legends__item">вес</span>
                  <span className="legends__item energy">калории</span>
                  <span className="legends__item">белки</span>
                  <span className="legends__item">жиры</span>
                  <span className="legends__item carbohydrates">углеводы</span>
                </span>
              </p>
            ) : null}
          </div>
        ) : null}
      </div>

      <div className="collapse-block__body" ref={refBody}>
        <div className="collapse-block__content" ref={refContent}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default Collapse
