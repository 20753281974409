import { IS_PROD_DOMAIN } from './prod'

export const SLUGS_TEXT_PAGES = IS_PROD_DOMAIN ? {
  SECURITY_POLICY: '5',
  OFFER: '7',
  PROMO: '8',
} : {
  SECURITY_POLICY: '5',
  OFFER: '7',
  PROMO: '8',
}
