const getCounters = (list) => {
  const countProducts = {}
  const countOptions = {}

  list.forEach((item, index) => {
    if (countProducts[item.productId]) {
      countProducts[item.productId].quantity += item.quantity
    } else {
      countProducts[item.productId] = {
        id: item.productId,
        name: item.name,
        quantity: item.quantity,
        availQuantity: item.availQuantity,
        // availQuantity: index * 2,
      }
    }

    item.options.forEach(({ dict_option_value_id, name, quantity, availQuantity }) => {
      if (countOptions[dict_option_value_id]) {
        countOptions[dict_option_value_id].quantity += quantity * item.quantity
      } else {
        countOptions[dict_option_value_id] = {
          id: dict_option_value_id,
          name,
          quantity: quantity * item.quantity,
          availQuantity
        }
      }
    })
  })

  return { products: countProducts, options: countOptions }
}

const getInvalidByCount = (obj = {}) => {
  let res = null
  Object.entries(obj).map(([key, item]) => {
    if (item.quantity > item.availQuantity) {
      if (res) {
        res[key] = item
      } else {
        res = { [key]: item }
      }
    }
  })

  return res
}

const getInvalidCounters = ({ products, options }) => {
  const countProducts = getInvalidByCount(products)
  const countOptions = getInvalidByCount(options)

  return { products: countProducts, options: countOptions }
}

const getError = (item, currentInvalidProduct, currentInvalidOptions) => {
  const isFullDisabled = currentInvalidProduct?.availQuantity === 0 || (currentInvalidOptions.length !== 0 && !currentInvalidOptions.some(({ availQuantity }) => availQuantity > 0))
  const isPartDisabled = !isFullDisabled && !!currentInvalidProduct
  const isDisabledMods = !isFullDisabled && currentInvalidOptions.length !== 0

  const messages = []
  if (isFullDisabled) {
    messages.push('Обратите внимание! Для следующих товаров не хватает всех ингредиентов <br/>Удалите товар')
  }

  if (isPartDisabled) {
    messages.push(`Обратите внимание! Для следующих товаров недоступно увеличение количества. Скорректируйте товары. <br/>Осталось: ${currentInvalidProduct.availQuantity}, всего требуется: ${currentInvalidProduct.quantity}`)
  }

  if (isDisabledMods) {
    let text = 'Обратите внимание! Для следующих товаров не хватает части ингредиентов:'
    currentInvalidOptions.forEach((item) => {
      if (item.availQuantity === 0) {
        text += `<br/>${item.name} закончился.`
        return
      }

      text += `<br/>${item.name}- требуется: ${item.quantity}; доступно: ${item.availQuantity}`
    })

    text += '<br/>Скорректируйте товары'

    messages.push(text)
  }

  return {
    messages, statuses: { isFullDisabled, isPartDisabled, isDisabledMods }, invalidOptions: currentInvalidOptions
  }
}

const getFilterInvalidOptionsBySingleProduct = (options, invalidCountersOptions) => {
  if (!invalidCountersOptions) {
    return []
  }

  const result = []

  options.forEach(({ dict_option_value_id }) => {
    if (invalidCountersOptions[dict_option_value_id]) {
      result.push(invalidCountersOptions[dict_option_value_id])
    }
  })

  return result
}


const getValidationProductList = (list = [], { optionValues, products }) => {
  const result = {
    valid: [],
    invalid: [],
  }

  const counters = getCounters(list)
  const invalidCounters = getInvalidCounters(counters)

  // Частный случай: Если инвалидные счетчики пусты, то возвращаем все товары в качестве валидных.
  if (!invalidCounters.options && !invalidCounters.products) {
    return {
      valid: list,
      invalid: [],
    }
  }

  list.forEach((item) => {
    const currentInvalidProduct = invalidCounters.products?.[item.productId]
    const currentInvalidOptions = getFilterInvalidOptionsBySingleProduct(item.options, invalidCounters.options)
    if (!currentInvalidProduct && currentInvalidOptions.length === 0) {
      result.valid.push(item)
      return
    }

    result.invalid.push({
      ...item,
      status: 'warning',
      error: getError(item, currentInvalidProduct, currentInvalidOptions)
    })
  })

  result.invalid.sort((a) => a.error.statuses.isFullDisabled ? 1 : -1)

  return result
}

const getOptionsForSend = (options = []) => {
  const result = {}

  options.forEach(({ option_id, option_value_id, price, quantity }) => {
    const item = { option_id, option_value_id, price, quantity }
    if (result[option_id]) {
      result[option_id].push(item)
    } else {
      result[option_id] = [item]
    }
  })

  return result
}

export const getProductsForSend = (list = []) => {
  const result = list.map(({ productId, quantity, price, options = [] }) => ({
    productId,
    quantity,
    price,
    options: getOptionsForSend(options)
  }))

  return result
}

export default getValidationProductList
