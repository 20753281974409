import {useCallback, useEffect, useState, useRef} from 'react'
import _throttle from 'lodash/throttle'
import StickyFilters from './sticky'

export const useScrollCategory = (count, config = {}, changed = false) => {
    const timerClick = useRef({ isClick: false, timer: null })
    const refRootSections = useRef(null)
    const refRootTabs = useRef(null)
    const refScrollTabs = useRef(null)
    const [activeModifiers, setActiveModifiers] = useState({ id: null })
    // const setActiveModifiersThrottle = useCallback(_throttle((temp) => setActiveModifiers(temp), 60), []);

    useEffect(() => {
        if (!activeModifiers.id) {
            return
        }

        const root = refScrollTabs.current
        if (!root) {
            return
        }

        const block = root.querySelector(`[data-tab-id="${activeModifiers.id}"]`)
        if (!block) {
            return
        }
        const left = block.offsetLeft
        root.scrollTo({
            top: 0,
            left: left - 40,
            behavior: 'smooth'
          })
    }, [activeModifiers.id, refScrollTabs, changed])

    useEffect(() => {
        if (!config.isStickyTabs) {
            return
        }

        const stickyFilters = new StickyFilters({ el: refRootTabs.current })
        stickyFilters.init()
        return () => {
            stickyFilters.destroy()
        }
    }, [config.isStickyTabs])

    useEffect(() => {
        if (count === 0) {
            return
        }

        const root = refRootSections.current
        if (!root) {
            return
        }

        const items = root.querySelectorAll('[data-section-id]') || []
        const onScroll = () => {
            if (timerClick.current.isClick) {
                return
            }
            const heightWin = (window.innerHeight || document.documentElement.clientHeight) / 3
            let active = {
                id: null,
                top: 0,
            }

            items.forEach((item, index) => {
                const id = item.getAttribute('data-section-id')
                const name = item.getAttribute('data-section-id')
                const rect = item.getBoundingClientRect()
                if (index === 0) {
                    active = { id, top: Math.abs(heightWin - rect.top) }
                }

                if (active.top > Math.abs(rect.top)) {
                    active = { id, top: Math.abs(heightWin - rect.top) }
                }
            })

            setActiveModifiers({ id: active.id })
        }

        const onScrollThrottle = _throttle(onScroll, 100)
        onScroll()
        window.addEventListener('scroll', onScrollThrottle)

        return () => {
            window.removeEventListener('scroll', onScrollThrottle)
        }
    }, [count, refRootSections, changed])

    const onClick = useCallback((id) => {
        const root = refRootSections.current
        if (!root) {
            return
        }

        const block = root.querySelector(`[data-section-id="${id}"]`)
        if (!block) {
            return
        }

        const rect = block.getBoundingClientRect()
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        setActiveModifiers({ id })
        clearTimeout(timerClick.current.timer)
        timerClick.current.isClick = true
        window.scrollTo({
            top: rect.top + scrollTop - config.addOffset,
            left: 0,
            behavior: 'auto'
        })
        timerClick.current.timer = setTimeout(() => {
            timerClick.current.isClick = false
        }, 1000)
    }, [refRootSections, config.addOffset])

    return {
        activeModifiers,
        onClick,
        refRootSections,
        refRootTabs,
        refScrollTabs,
    }
}
