import React, { useCallback, useState } from 'react'
import clsx from 'clsx'
import Modal from '../modal'
import SelectLink from '../select-link'
import SoldLabel from '../sold-label'
import { getFormattedPrice, roundedNum } from '../../util/number'
import { getStatsProduct } from "../../util/product/stats"
import emptyOptionImg from '../../img/empty/option.png'
import './styles.scss'

const CardBase = ({
  activeBase,
  activeSize,
  list,
  onChange,
}) => {
  const [modal, setModal] = useState({
    visible: false,
    currentList: [],
    id: null,
    active: null
  })
  const onClose = useCallback(() => setModal((prev) => ({ ...prev, visible: false })), [])
  const onShow = useCallback((id) => {
    const current = list.find(({ option_id }) => id === option_id)
    setModal({
      id,
      currentList: current.items,
      visible: true,
    })
  }, [list])

  const onClick = useCallback((id, item) => {
    onChange(id, item)
    onClose()
  }, [onChange, onClose])

  return (
    <>
      <div className="base">
        <div className="grid-inner">
          <div className="base__body">
            {list.map(({ option_id, name: groupName }, index) => {
              const activeItem = activeBase[option_id]

              if (!activeItem) {
                return (
                  <div className="base__item" key={option_id}>
                    <SelectLink onClick={() => onShow(option_id)}>
                      <div className="base__item-block">
                        <div className="base__item-image"><img src={emptyOptionImg} alt="" /></div>
                        <div className="base__item-content">
                          <p className="base__item-name">
                            <span>{groupName}</span><br />
                            Не выбрано
                          </p>
                        </div>
                      </div>
                    </SelectLink>
                  </div>
                )
              }
              return (
                <div className="base__item" key={option_id}>
                  <SelectLink onClick={() => onShow(option_id)}>
                    <div className="base__item-block">
                      <div className="base__item-image"><img src={activeItem.image || emptyOptionImg} alt="" /></div>
                      <div className="base__item-content">
                        {activeItem.isNotSelected ? (
                          <p className="base__item-name">
                            <span>{activeItem.groupName}</span><br />
                            {activeItem.originName}
                          </p>
                        ) : (
                          <p className="base__item-name">{activeItem.name}</p>
                        )}
                        <p className="base__item-stats">
                          {getStatsProduct(activeItem.nutrition, activeSize.coefficient)}
                        </p>
                      </div>
                      <p className="base__item-right">
                        {activeItem.weight ? <span className="base__item-weight">{roundedNum(activeItem.weight * activeSize.coefficient, true)} г</span> : null}
                        <span className="base__item-price">{getFormattedPrice(activeItem.price * activeSize.coefficient)} ₽</span>
                      </p>
                    </div>
                  </SelectLink>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <Modal
        visible={modal.visible}
        onClose={onClose}
        title="Выбор основы"
        mod="cart"
      >
        <div className="base-modal base-modal--pb-large">
          {modal.currentList.map((item) => {
            const disabled = item.quantity < activeSize.coefficient
            return (
              <div
                className={clsx('base-modal__item', activeBase[modal.id]?.option_value_id === item.option_value_id && 'active', disabled && 'disabled')}
                onClick={() => !disabled && onClick(modal.id, item)}
                key={item.option_value_id}
              >
                <div className="base-modal__item-image"><img src={item.image || emptyOptionImg} alt="" /></div>
                <div className="base-modal__item-content">
                  {item.isNotSelected ? (
                    <p className="base__item-name">
                      <span>{item.groupName}</span><br />
                      {item.originName}
                    </p>
                  ) : (
                    <p className="base__item-name">{item.name}</p>
                  )}
                  <p className="base-modal__item-stats">
                    {getStatsProduct(item.nutrition, activeSize.coefficient)}
                  </p>
                </div>
                {disabled ? <SoldLabel /> : null}
                <p className="base-modal__item-right">
                  {item.weight ? <span className="base-modal__item-weight">{roundedNum(item.weight * activeSize.coefficient, true)} г</span> : null}
                  <span className="base-modal__item-price">{getFormattedPrice(item.price * activeSize.coefficient)} ₽</span>
                </p>
              </div>
            )
          })}
        </div>
      </Modal>
    </>
  )
}

export default CardBase
