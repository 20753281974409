import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { isAuthSelector } from '../store/selectors/user'

const AuthRoute = ({ component: Component, redirect = { pathname: '/' }, ...rest }) => {
    const isAuth = useSelector(isAuthSelector)

    return (
        <Route
            {...rest}
            render={props =>
                isAuth ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={{ ...redirect, state: { from: props.location } }} />
                )
            }
        />
    )
}

export default AuthRoute;
