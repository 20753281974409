import React, {useEffect, useState} from 'react';
import './styleApp.scss';
import {BrowserRouter, Route, Switch, Redirect} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import API from "./api";
import { setUserToken, logoutUser, getInfoUser, setUserTemp } from "./store/actions/user";
import Head from "./components/head";
import TopMove from "./components/top-move";
import CustomRedirect from "./components/custom-redirect";
import Main from "./route/Main/Main";
import Maps from "./route/Map/Maps.jsx";
import Address from "./route/Address/Address";
import Login from "./route/Login/Login";
import Code from "./route/Login/Code";
import Lk from "./route/Lk/Lk";
import Cart from "./route/Cart";
import LkRedact from "./route/Lk/LkRedact";
// import Chat from "./route/Chat/Chat";
// import Bonuses from "./route/Bonuses/Bonuses";
import Orders from "./route/Orders/OrderListPage";
// import Recommendations from "./route/Recommendations/Recommendations";
import Preferences from "./route/Preferences/Preferences";
import Catalog from "./route/Catalog/Catalog";
import Category from "./route/Category/Category";
import Subcategory from "./route/Subcategory/Subcategory";
import Product from "./route/Product/Product";
// import Payments from "./route/Payments/Payments";
// import Time from "./route/Time/Time";
import OrderAccepted from "./route/OrderAccepted/OrderAccepted";
import Thanks from "./route/Thanks/Thanks";
// import StatusKitchen from "./route/StatusKitchen/StatusKitchen";
// import Preloader from "./route/Preloader/Preloader";
// import Lending from "./route/Lending/lending";
import TextPage from "./route/Text-page/page";
import TextPages from "./route/Text-page/pages";
import GuestRoute from "./route/GuestRoute";
import AuthRoute from "./route/AuthRoute";
import Page404 from "./route/Page404";
import PageError from "./route/PageError";

const App = () => {
    const [showApp, setShowApp] = useState(false)
    const dispatch = useDispatch()
    const userToken = useSelector((store) => store.user.token)

    useEffect(() => {
        const callbacks = {
            login: (token) => dispatch(setUserToken(token)),
            // TODO: temp hide
            temp: (token, telephone, password) => {
                dispatch(setUserTemp({ token, telephone, password }))
                // После создания временного юзера, получаем инфу о нем
                dispatch(getInfoUser())
            },
            logout: () => dispatch(logoutUser()),
        }
        // Устанавливаем коллбеки
        API.setCallbacks(callbacks)
    }, [dispatch])
    useEffect(() => {
        // Если есть креды, авторизуем
        if (userToken) {
            API.loginByToken(userToken)
            dispatch(getInfoUser())
        } else {
            API.logout()
        }

        // Пускаем в приложение
        setShowApp(true)
    }, [dispatch])

    // const appHeight = () => {
    //     document.getElementById('app').style.height = window.innerHeight + 'px';
    // }
    // window.addEventListener('resize', appHeight);
    // appHeight();

    if (!showApp) {
        return null
    }

    return (
        <BrowserRouter>
            <Head />
            <Switch>
                {/* <AuthRoute path="/StatusKitchen" exact component={StatusKitchen}/> */}

                <GuestRoute exact path="/" component={Main}/>
                {/* <Route path="/Preloader" exact component={Preloader}/> */}
                <Route path="/Map" exact component={Maps}/>
                <Route path="/Address" exact component={Address}/>
                <GuestRoute path="/Login" redirect={{ pathname: '/Lk' }} exact component={Login}/>
                <GuestRoute path="/Code" exact component={Code}/>

                <AuthRoute path="/Cart" exact component={Cart}/>
                <AuthRoute path="/Lk" exact component={Lk}/>
                <AuthRoute path="/LkRedact" exact component={LkRedact}/>
                <AuthRoute path="/Orders" exact component={Orders}/>
                {/* <AuthRoute path="/Recommendations" component={Recommendations}/> */}
                <AuthRoute path="/Catalog" exact component={Catalog}/>
                <AuthRoute path="/Category" redirect={{ pathname: '/', search: '?non-existent' }} component={Category}/>
                <AuthRoute path="/Subcategory/:alias" exact component={Subcategory}/>
                <AuthRoute path="/Product/:alias" exact component={Product}/>
                {/* TODO */}
                <AuthRoute path="/OrderAccepted" exact component={OrderAccepted} />
                <AuthRoute path="/Thanks" exact component={Thanks} />

                <AuthRoute path="/Preferences" exact component={Preferences}/>
                {/* <AuthRoute path="/Chat" component={Chat}/> */}
                {/* <AuthRoute path="/Bonuses" component={Bonuses}/> */}
                {/* <AuthRoute path="/Payments" exact component={Payments}/> */}
                {/* <AuthRoute path="/Time" exact component={Time}/> */}

                {/* <Route path="/Lending" exact component={Lending}/> */}
                <Route path="/pages/:alias" exact component={TextPage}/>
                <Route path="/pages" exact component={TextPages}/>
                <Route path="/404" exact component={Page404}/>
                <Route path="/error" exact component={PageError}/>

                {/* <Redirect to="/404" /> */}
                <CustomRedirect />
            </Switch>
            <TopMove />
        </BrowserRouter>
    );
};

export default App;
