const TEXTS = {
  dish: {
    other: 'блюд',
    many: 'блюда',
    one: 'блюдо',
  },
  plate: {
    other: 'тарелок',
    many: 'тарелки',
    one: 'тарелка',
  }
}

const getKey = (value) => {
  const n = Math.abs(value) % 100
  const n1 = n % 10

  switch (true) {
    case n > 10 && n < 20: {
      return 'other'
    }
    case n1 > 1 && n1 < 5: {
      return 'many'
    }
    case n1 === 1: {
      return 'one'
    }
    default: {
      return 'other'
    }
  }
}

const declOfNum = (value = 0, name) => {
  const key = getKey(value)
  return TEXTS?.[name]?.[key] || ''
}

export default declOfNum
