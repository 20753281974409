// const listServing = ['Подать холодным', 'Подать горячим'].map((val) => val.toLowerCase())
const listServing = [{ code: '76138' }, { code: '76139' }]
// const listTaste = ['Без соли', 'Без масла', 'Острее!'].map((val) => val.toLowerCase())
const listTaste = [{ code: '76140' }, { code: '76141' }, { code: '76142' }]

export const SERVE_COLD_ID = '76138'
export const SERVE_HOT_ID = '76139'

export const getListsOptionsFromWishes = (wishes) => {
  const result = {
    wishes: { option_id: null, items: [] },
    serving: { option_id: null, items: [] },
    taste: { option_id: null, items: [] },
  }

  if (!wishes || !wishes?.items?.length) {
    return result
  }

  result.wishes.option_id = wishes.option_id
  result.serving.option_id = wishes.option_id
  result.taste.option_id = wishes.option_id

  wishes.items.forEach(item => {
    if (listServing.some(({ code }) => code === item.code)) {
      result.serving.items.push(item)
      return
    }
    if (listTaste.some(({ code }) => code === item.code)) {
      result.taste.items.push(item)
      return
    }

    result.wishes.items.push(item)
  })

  return result
}
