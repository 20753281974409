import { useEffect} from 'react'
import _throttle from 'lodash/throttle'

const useOutsideAlerter = (ref, callback) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback()
      }
    }

    const scrollThrottle = _throttle(callback, 100)
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('scroll', scrollThrottle)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('scroll', scrollThrottle)
    };
  }, [ref, callback]);
}

export default useOutsideAlerter
