import React, { memo } from 'react'
import clsx from 'clsx'
import { roundedNum } from "../../util/number"
import './styles.scss'

const StatsItem = ({ value = '', postfix = '', mods = '' }) => (
  <div className={clsx('compound-item__stats-item', mods)}>{roundedNum(value, true)} {postfix}</div>
)

const CompoundItem = ({ data }) => {
  return (
    <div className="compound-item">
      <p className="compound-item__name">{data.name} — x{data.quantity}</p>
      <div className="compound-item__stats">
        <StatsItem value={data.weight * data.quantity} postfix='г' mods="weight" />
        <StatsItem value={data.nutrition.energy_full  * data.quantity} postfix='кКал' mods="energy" />
        <StatsItem value={data.nutrition.proteins_full * data.quantity} postfix='г'/>
        <StatsItem value={data.nutrition.fat_full * data.quantity} postfix='г'/>
        <StatsItem value={data.nutrition.carbohydrates_full * data.quantity} postfix='г' mods="carbohydrates" />
      </div>
    </div>
  )
}

const CompoundTable = memo(function CompoundTable({ data = [] }) {
  return (
    <div className="compound-table">
      {data.map((item) => (
        <CompoundItem key={item.id} data={item}/>
      ))}
    </div>
  )
})

export default CompoundTable
