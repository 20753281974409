import React from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import Icon from '../../img/icons/prev-arrow.svg'
import './styles.scss'

const LinkBtn = ({
  onClick = () => {},
  to = '#',
  children,
  mod,
}) => {

  return (
    <Link className={clsx('link-btn', mod)} to={to} onClick={onClick}>
      <div className="link-btn__content" >{children}</div>
      <div className="link-btn__icon"><Icon /></div>
    </Link>
  )
}

export default LinkBtn
