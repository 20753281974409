import { combineReducers } from 'redux'
import userReducer from './user'
import cartReducer from './cart'
import orderReducer from './order'

const rootReducer = combineReducers({
    user: userReducer,
    cart: cartReducer,
    order: orderReducer,
})

export default rootReducer
