import API from "../api";
import {useReducer} from "../util/store";
const [alert, setAlert] = useReducer('alert');

export const getListArticles = (callback, failCallback = () => {}) => {
    API.get(
        '/articles',
        function (response) {
            if(response.message){
                setAlert({danger: response.message});
                failCallback(response);
            } else {
                callback(response);
            }
        },
        function (error) {
            failCallback(error);
        }
    );
}

export const getArticleById = (id, callback, failCallback = () => {}) => {
    API.get(
        '/article/' + id,
        function (response) {
            if(response.message){
                setAlert({danger: response.message});
                failCallback(response);
            } else {
                callback(response);
            }
        },
        function (error) {
            failCallback(error);
        }
    );
}
