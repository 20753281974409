import React, { useCallback } from 'react'
import clsx from 'clsx'
import './styles.scss'

const Count = ({
    onChange,
    quantity,
    disabled = false,
    mods = '',
    disabledAdd = false,
}) => {
    const onRemove = useCallback((e) => {
      e.stopPropagation()
      onChange(false)
    }, [onChange])
    const onAdd = useCallback((e) => {
      e.stopPropagation()
      if (disabledAdd) {
        return
      }

      onChange(true)
    }, [onChange, disabledAdd])

    return (
        <div className={clsx('count', mods)}>
            {disabled ?  <div className="count__disabled" /> : null}
            <div className="count__control count__control--minus" onClick={onRemove}>-</div>
            <div className="count__result">{quantity}</div>
            <div className={clsx('count__control count__control--plus', disabledAdd && 'count__control--disabled')} onClick={onAdd}>+</div>
        </div>
    );
};

export default Count;
