import API from "../api";

export const getUser = (callback, errorCallback) =>{
  API.get('/user',
    function (response){
      if(response && callback) callback(response);
    },
    function (response){
      if(response && errorCallback) errorCallback(response);
    }
  );
}

export const editUser = (body) => (
  new Promise((resolve, reject) => {
    API.post('/user',
      body,
      resolve,
      reject,
    )
  })
)

export const getActiveOrdersCounter = (callback, errorCallback) => {
    API.get(
        '/user/activeOrdersCount',
        function (response){
            if(callback && response) callback(response);
        },
        function (response){
            if(response && errorCallback) errorCallback(response);
        }
    );
}
