const incrementHeight = (el, progress) => {
  el.style.height = `${progress * el.scrollHeight}px`
}

const decrementHeight = (el, progress) => {
  el.style.height = `${el.scrollHeight - progress * el.scrollHeight}px`
  el.style.overflow = 'hidden'
}

export const slideDown = (el, duration) => {
  const start = performance.now()

  requestAnimationFrame(function animate(time) {
    const runtime = time - start
    const relativeProgress = runtime / duration
    const process = Math.min(relativeProgress, 1)

    if (process < 1) {
      incrementHeight(el, process)
      requestAnimationFrame(animate)
    }

    if (process === 1) {
      el.style.height = 'auto'
      el.style.overflow = 'initial'
    }
  })
}

export const slideUp = (el, duration) => {
  const start = performance.now()

  requestAnimationFrame(function animate(time) {
    const runtime = time - start
    const relativeProgress = runtime / duration
    const process = Math.min(relativeProgress, 1)

    if (process < 1) {
      decrementHeight(el, process)
      requestAnimationFrame(animate)
    }

    if (process === 1) {
      el.style.height = ''
      el.style.overflow = ''
    }
  })
}
