import React, { useCallback, useState } from 'react'
import clsx from 'clsx'
import IconPlay from '../../../img/icons/play.svg'
import Onboarding from '../index'
import Modal from '../../modal'
import './styles.scss'

const OnboardingPreview = ({
  innerClassName = '',
  title = '',
}) => {
  const [visible, toggleVisible] = useState(false)
  const onShow = useCallback(() => toggleVisible(true), [])
  const onHide = useCallback(() => toggleVisible(false), [])

  return (
    <>
      <div className={clsx('onboarding-preview', innerClassName)} >
        <div className="onboarding-preview__inner">
          {title ? <p className="onboarding-preview__title">{title}</p> : null}
          <div className="onboarding-preview__video-wrap">
            <div className="onboarding-preview__play" onClick={onShow}>
              <IconPlay />
            </div>
          </div>
        </div>
      </div>
      <Modal
        visible={visible}
        onClose={onHide}
        title=''
        mod="onboarding"
      >
        <Onboarding title="Как работает Тарелка?" autoPlay={true} />
      </Modal>
    </>
  )
}

export default OnboardingPreview
