
const getPriceAllModifiers = (options, ingredients) => {
  let price = 0
  let ingr = {}

  if(ingredients) {
    ingredients.forEach(item => {
      ingr[item.option_id] = item.free_count
    })
  }

  // options - выбранные модификаторы пользователем
  if (options) {
    for (let option_id in options) {
      options[option_id].map(item => {
        let _price = item.price
        let qty = item.quantity || 1
        if (ingr[option_id] && ingr[option_id] > 0) {
          let add = Math.min(ingr[option_id], item.quantity)
          qty -= add
          ingr[option_id] = ingr[option_id] - item.quantity
        }

        if (_price) {
          price += _price * qty
        }
      })
    }
  }

  return price
}

const getPriceAllBasics = (basics = [], activeBase, activeSize) => {

  return basics.reduce((sum, item) => {
    const active = activeBase[item.option_id]
    if (active?.price) {
        return sum + (activeBase[item.option_id].price * activeSize.coefficient)
    }

    return sum
  }, 0)
}

export const getAllPrice = ({ ingredients, basics }, options, activeBase, activeSize) => {
  const priceAllModifiers = getPriceAllModifiers(options, ingredients)
  const priceAllBasics = getPriceAllBasics(basics, activeBase, activeSize)

  return priceAllModifiers + priceAllBasics
}
