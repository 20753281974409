const formatDate = (str = '') => {
    const date = new Date(str)
    const month = `${date.getMonth() + 1}`.padStart(2, '0');
    const day = `${date.getDate()}`.padStart(2, '0');
    const year = date.getFullYear();
    const hour = `${date.getHours()}`.padStart(2, '0');
    const minute = `${date.getMinutes()}`.padStart(2, '0');

    // "DD.MM.YYYY, HH:mm
    return `${day}.${month}.${year}, ${hour}:${minute}`
}

export default formatDate
