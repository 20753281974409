import React, { useCallback, useEffect, useMemo, useState } from 'react'
import clsx from 'clsx'
import { getFormattedPrice, roundedNum } from '../../util/number'
import { MAX_COUNT_MODIFIERS_BY_SINGLE_GROUP } from '../../constants/modifiers'
import emptyOptionImg from '../../img/empty/option.png'
import Count from '../count'
import Image from '../image'
import SoldLabel from '../sold-label'
import Tooltip from './tooltip'
import './styles.scss'

const Option = ({
  data: option,
  added,
  free_count,
  // quantity: initialQuantity,
  quantity,
  onChange,
  showAlertModifiers,
  index,
}) => {
  const disabled = useMemo(() => option.quantity === 0, [option.quantity])
  const availDisabled = useMemo(() => {
    const diff = option.quantity - quantity

    return diff <= 0
  }, [option.quantity, quantity])

  const onHandlerChange = useCallback((plus) => {
    if (plus && added >= MAX_COUNT_MODIFIERS_BY_SINGLE_GROUP) {
      showAlertModifiers()
      return
    }
    if (plus && availDisabled) {
      showAlertModifiers({ isDisabled: true })
      return
    }

    let qty = quantity;
    if(plus) qty++;
    if(!plus) qty--;
    if(qty < 0) qty = 0;

    onChange({
      option_value_id: option.option_value_id,
      quantity: qty,
      price: option.price
    });
  }, [quantity, onChange, showAlertModifiers, option, added])

  const onClick = useCallback(() => {
    if (disabled) {
      return
    }

    if (quantity > 0) {
      const qty = 0
      onChange({
        option_value_id: option.option_value_id,
        quantity: qty,
        price: option.price
      });
      return
    }
    if (added >= MAX_COUNT_MODIFIERS_BY_SINGLE_GROUP) {
      showAlertModifiers()
      return
    }
    onHandlerChange(true)
  }, [onHandlerChange, showAlertModifiers, onChange, option, added, availDisabled, disabled, quantity])

  return (
    <div className={clsx('modifiers-card', quantity > 0 && 'hover', disabled && 'disabled')} onClick={onClick}>
      <Tooltip index={index} mods="modifiers-card__tooltip" option={option} />
      {disabled ? (
        <SoldLabel mods="modifiers-card__disabled"/>
      ) : null}
      {!disabled && quantity ? (
        <div className="modifiers-card__all-price">
          +{quantity < free_count ? 0 : getFormattedPrice((quantity - free_count) * option.price)} ₽
        </div>
      ) : null}
      <div className="modifiers-card__img">
        <Image src={option.image} alt="" emptyImg={emptyOptionImg} />
      </div>
      <div className="modifiers-card__name">{option.name}</div>
      {option.weight ? <div className="modifiers-card__weight">{roundedNum(option.weight * (quantity || 1), true)} г</div> : null}
      <div className="modifiers-card__bottom">
        {quantity > 0 ? (
          <Count quantity={quantity} onChange={onHandlerChange} mods="option" />
        ) : (
          <div className="modifiers-card__price">+{added < free_count ? 0 : getFormattedPrice(option.price)} ₽</div>
        )}
      </div>
    </div>
  )
}

export default Option
