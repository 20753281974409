import React, {useCallback, useEffect, useState} from 'react'
import clsx from 'clsx'
import API from '../../api'
import Modal from '../modal'
import { SHIPPING_CODES, NOW_SHIPPING_FLAT, NOW_SHIPPING_PICKUP } from '../../constants/shipping'
import './styles.scss'

const CartTime = ({
  visible = false,
  onClose = () => {},
  setCartData,
  time = null,
  title = '',
  shipping = null
}) => {
  const [times, setTimes] = useState([NOW_SHIPPING_FLAT])

  useEffect(() => {
    if (visible) {
      const now = shipping?.code === SHIPPING_CODES.PICKUP ? NOW_SHIPPING_PICKUP : NOW_SHIPPING_FLAT
      setTimes([now])
      API.get(
        '/order/timeslots',
        (response) => {

          const slots = [
            now,
            ...response.slots.map((item) => ({ ...item, value: `${item.from} - ${item.to}` })),
          ]
          setTimes(slots)
        }
      )
    }
  }, [shipping?.code, visible])

  const onChange = useCallback(async (time) => {
    try {
      await setCartData({time: time})
    } catch (error) {
      console.log(error)
    }
  }, [setCartData])

  return (
    <Modal
      visible={visible}
      onClose={onClose}
      title={title}
      btn={{ text: 'Сохранить', onClick: onClose }}
    >
      <div className={"cart-time " + (times[0] && times[0].default ? 'loadData' : '')}>
        {times.map(({ value }) => (
          <div className="cart-time__item-wrap" key={value}>
            <div className={clsx('cart-time__item', time === value && 'active')} onClick={() => onChange(value)}>
              <p className="cart-time__item-value text">{value}</p>
            </div>
          </div>
        ))}
      </div>
    </Modal>
  )
}

export default CartTime
