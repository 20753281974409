import API from "../api";
import { useReducer } from "../util/store";
import dishesImg from '../img/empty/catalog-item.png'
import dishesDetailsImg from '../img/empty/product-details.png'
const [alert, setAlert] = useReducer( 'alert' );

export const defaultProduct = {
    default: true,
    id: 0,
    link: "/Product",
    image: dishesDetailsImg,
    name: "",
    price:"0",
    oldPrice: "0",
    attributes: [],
    options: [],
    ingredients: [],
    ingredientsFreeCount: 0,
    basics: [],
    cooktypes: null,
    wishes: null,
    // statusDish: [
    //     {id: 1, img: statusDish1 },
    //     {id: 2, img: statusDish2 },
    //     {id: 3, img: statusDish3 },
    // ],
    // statusOrder: [
    //     {id: 1, class: "-combo", text: "1+1" },
    //     {id: 2, class: "-discount", text: "-45%" },
    //     {id: 3, class: "-new", text: "new" },
    // ]
}

const defaultProductForList = {
    ...defaultProduct,
    image: dishesImg,
}
export const defaultProducts = [
    { defaultProduct: defaultProductForList, id: 1 },
    { defaultProduct: defaultProductForList, id: 2 },
    { defaultProduct: defaultProductForList, id: 3 },
    { defaultProduct: defaultProductForList, id: 4 },
    { defaultProduct: defaultProductForList, id: 5 },
    { defaultProduct: defaultProductForList, id: 6 },
]

const status = {
    isLoading: false,
    sub: []
}

export const getProducts = (data, callback, failCallback = () => {}, config = {}) => {
    if(!status.isLoading) {
        status.isLoading = true;
        API.post(
            '/menu/products/' + data.alias,
            data,
            function (response) {
                status.isLoading = false;
                if (callback && response.products) {
                    status.sub.map(call => {
                        call(response);
                    });
                    callback(response);
                }

                if (!response.products && response.message) {
                    failCallback(response);
                }
            },
            failCallback,
            config,
        );
    } else {
        status.sub.push(callback);
    }
}

export const getProduct = (alias, callback, failCallback = () => {}, config = {}) => {
    API.get(
        '/menu/product/'+alias,
        function (response) {
            if(response.message){
                setAlert({danger: response.message});
                failCallback(response);
            } else {
                callback(response);
            }
        },
        function (error) {
            failCallback(error);
        },
        config,
    );
}
