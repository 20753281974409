import React, { useMemo } from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { getOptions } from './utils'
import Image from '../image'
import SoldLabel from '../sold-label'
import WarningMessage from '../warning-message'
import { getFormattedPrice } from '../../util/number'
import { getAllStatsOrderProduct, nonBreakingSpace } from '../../util/product/stats'
import { roundedNum } from '../../util/number'
import { getQueryFromOptions } from '../../util/product/query'
import emptyImg from '../../img/empty/order.png'
import './styles.scss'


const OptionsList = ({ options = [], type = 'valid' }) => {
  return (
    <div className={clsx('order-product__options', `order-product__options--${type}`)}>
      {type === 'invalid' ? (
        <div className="order-product__options-separator">Не хватает</div>
      ) : null}
      {options.map((item, index) => (
        <div className="order-product__option-item" key={index}>
          {item.name}{item.quantity ? ` - x${item.quantity}` : ''}{nonBreakingSpace}
          {item.weight ? `, ${roundedNum(item.weight * item.quantity, true)}${nonBreakingSpace}г` : ''}
        </div>
      ))}
    </div>
  )
}

const OrderProduct = ({
  data: product,
  quantity = 1,
  type = 'order',
  children,
  isFullDisabled = false,
  isPartDisabled = false,
  messages = [],
  error = null
}) => {
  const renderName = useMemo(() => {
    if (type === 'order') {
      return `${product.name}${product.quantity ? ` - x${product.quantity}` : ''}`
    }

    return product.name
  }, [product.name, product.quantity, type])

  const link = useMemo(() => {
    try {
      const query = getQueryFromOptions(product.options, null, true)
      const id = product.productId || product.product_id

      if (id) {
        return `/Product/${id}?${query}`
      }
    } catch (error) {
      console.log(error)
    }

    return null
  }, [product])

  const renderInfo = useMemo(() => {
    const info = getAllStatsOrderProduct(product.options)
    const price = <span className="order-product__price">{getFormattedPrice(quantity * product.price)} ₽</span>
    const weight = info.weight ? <span className="order-product__weight">{info.weight}</span> : null
    const stats = info.stats ? <span className="order-product__stats"> | {info.stats}</span> : null

    return <>{price} {weight} {stats}</>

  }, [quantity, product])

  const options = useMemo(() => {
    if (error?.invalidOptions?.length) {
      return getOptions(product.options, error.invalidOptions)
    }

    return {
      valid: product.options,
      invalid: []
    }
  }, [product.options, error])

  return (
    <>
      {messages.length ? (
        <WarningMessage
          mods="order-product__warning"
          text={messages}
        />
      ) : null}

      <div className={clsx('order-product', `_${type}`, isFullDisabled && 'disabled', isPartDisabled && 'changed')}>
        {isPartDisabled ? (
          <SoldLabel mods="order-product__label" title="Частично<br/>раскуплен"/>
        ) : null}
        {isFullDisabled ? (
          <SoldLabel mods="order-product__label" title="Раскупили" />
        ) : null}
        <div className="order-product__inner">
          {link ? (
            <Link className="order-product__img" to={link}><Image src={product.image} alt="" emptyImg={emptyImg}/></Link>
          ) : (
            <div className="order-product__img"><Image src={product.image} alt="" emptyImg={emptyImg}/></div>
          )}
          <div className="order-product__content">
            {link ? (
              <Link className="order-product__name" to={link}>{renderName}</Link>
            ) : (
              <p className="order-product__name">{renderName}</p>
            )}
            <p className="order-product__info">
              {renderInfo}
            </p>
            {options.valid.length ? (
              <OptionsList options={options.valid} type="valid" />
            ) : null}
            {options.invalid.length ? (
              <OptionsList options={options.invalid} type="invalid" />
            ) : null}
          </div>
          {children ? (
            <div className="order-product__controls">
              {children}
            </div>
          ) : null}
        </div>
      </div>
    </>
  )
}

export default OrderProduct
