import React from 'react'
import FilterItem from './filter-item'
import './styles.scss'

const inArray = (tags = [], id) => tags.some(({ tagId }) => tagId === id)

const Filters = ({
  selected = [],
  groups = [],
  onChange = () => {},
  disabled = false,
}) => {
  return (
    <div className="filters">
      <div className="filters__top">
        <p className="filters__title">Фильтр Тарелок</p>
        <p className="filters__subtitle">Выберите теги по предпочтениям</p>
      </div>
      {selected.length ? (
        <div className="filters__selected">
          {selected.map(({ tagId, name, image }) => (
            <FilterItem key={tagId} id={tagId} value={name} image={image} onChange={onChange} active disabled={disabled}/>
          ))}
        </div>
      ) : null}

      <div className="filters__list">
        {groups.map((group) => (
        <div className="filters__group" key={group.groupId}>
          <p className="filters__group-caption">{group.name}</p>
          <div className="filters__group-content">
            {group.tags.map(({ tagId, name, image }) => {
              const isInArray = inArray(selected, tagId)
              return (
                <FilterItem key={tagId} id={tagId} value={name} image={image} onChange={onChange} active={isInArray} disabled={disabled}/>
              )
            })}
          </div>
        </div>
        ))}
      </div>
    </div>
  )
}

export default Filters
