import colors from './colors'

const ZONES = {
    1: [
        [37.627648,55.606198],
        [37.629246,55.605802],
        [37.632568,55.604461],
        [37.634467,55.606872],
        [37.638812,55.611056],
        [37.637374,55.61221],
        [37.634189,55.615115],
        [37.6324,55.617826],
        [37.630095,55.618593],
        [37.621882,55.619545],
        [37.608428,55.621585],
        [37.59279,55.624019],
        [37.591295,55.624203],
        [37.583792,55.625322],
        [37.582153,55.621451],
        [37.580675,55.621036],
        [37.579412,55.621397],
        [37.576971,55.621925],
        [37.575522,55.621087],
        [37.574546,55.620006],
        [37.580093,55.618252],
        [37.579803,55.615671],
        [37.579771,55.615149],
        [37.580254,55.614979],
        [37.580125,55.614226],
        [37.579911,55.613497],
        [37.591628,55.612677],
        [37.602122,55.611244],
        [37.618761,55.608678],
        [37.625278,55.607128],
        [37.627648,55.606198],
    ],
    2: [
        [37.586712,55.613008],
        [37.579498,55.613529],
        [37.577417,55.614282],
        [37.575829,55.613814],
        [37.574632,55.613281],
        [37.569113,55.608039],
        [37.575336,55.604189],
        [37.627592,55.600049],
        [37.632441,55.604483],
        [37.628922,55.605899],
        [37.624888,55.607168],
        [37.61829,55.608802],
        [37.610319,55.609998],
        [37.602562,55.61117],
        [37.594032,55.612366],
        [37.590565,55.612772],
        [37.586712,55.613008],
    ],
    3: [
        [37.596579,55.633564],
        [37.594852,55.632957],
        [37.59188,55.632059],
        [37.590282,55.630796],
        [37.588179,55.628575],
        [37.583737,55.625321],
        [37.622608,55.619446],
        [37.632296,55.618169],
        [37.628905,55.624763],
        [37.626931,55.632035],
        [37.622956,55.632071],
        [37.619239,55.632496],
        [37.612061,55.633637],
        [37.604862,55.634766],
        [37.599938,55.63422],
        [37.596579,55.633564],
    ],
    4: [
        [37.604464,55.601858],
        [37.578952,55.603813],
        [37.577876,55.599347],
        [37.576407,55.599401],
        [37.57644,55.597948],
        [37.578909,55.595674],
        [37.577781,55.592302],
        [37.582104,55.591482],
        [37.582608,55.593276],
        [37.583112,55.595495],
        [37.593475,55.595706],
        [37.599826,55.595623],
        [37.602228,55.595467],
        [37.608102,55.594677],
        [37.615902,55.593511],
        [37.627446,55.599975],
        [37.604464,55.601858],
    ],
    5: [
        [37.637433,55.64336],
        [37.63163,55.648369],
        [37.636411,55.650263],
        [37.633672,55.652605],
        [37.631767,55.652369],
        [37.629904,55.652885],
        [37.627774,55.65368],
        [37.625988,55.654693],
        [37.624867,55.655245],
        [37.623788,55.655457],
        [37.622887,55.653686],
        [37.6216,55.653583],
        [37.621278,55.654053],
        [37.619786,55.654215],
        [37.619727,55.653781],
        [37.619797,55.653261],
        [37.619786,55.632436],
        [37.621329,55.632247],
        [37.622989,55.632081],
        [37.626932,55.632054],
        [37.624456,55.634005],
        [37.623753,55.635029],
        [37.62373,55.636488],
        [37.624543,55.638359],
        [37.62427,55.640428],
        [37.628817,55.640444],
        [37.628467,55.641012],
        [37.635092,55.640306],
        [37.641657,55.641292],
        [37.642115,55.643052],
        [37.642388,55.644454],
        [37.641587,55.644716],
        [37.637433,55.64336],
    ],
    6: [
        [37.605297,55.639801],
        [37.605008,55.638045],
        [37.604325,55.636528],
        [37.602994,55.636542],
        [37.602928,55.636079],
        [37.602233,55.635467],
        [37.601501,55.634976],
        [37.6018,55.634411],
        [37.604932,55.634754],
        [37.61976,55.632442],
        [37.619754,55.653339],
        [37.605603,55.653097],
        [37.605297,55.639801],
    ],
    7: [
        [37.595459,55.58663],
        [37.595184,55.585783],
        [37.594373,55.583562],
        [37.597312,55.583216],
        [37.596904,55.581465],
        [37.596499,55.579658],
        [37.597401,55.578625],
        [37.598101,55.578171],
        [37.598628,55.578057],
        [37.599519,55.578236],
        [37.600895,55.5781],
        [37.600792,55.57863],
        [37.601703,55.578888],
        [37.601442,55.57853],
        [37.602615,55.578214],
        [37.603589,55.579491],
        [37.60058,55.580319],
        [37.601203,55.581803],
        [37.601502,55.582228],
        [37.601897,55.582185],
        [37.603182,55.58284],
        [37.604057,55.582547],
        [37.605269,55.582702],
        [37.611234,55.580909],
        [37.612328,55.582295],
        [37.613165,55.583729],
        [37.613668,55.587354],
        [37.614358,55.590772],
        [37.615847,55.5935],
        [37.610612,55.594287],
        [37.605097,55.595086],
        [37.602276,55.595465],
        [37.599862,55.595602],
        [37.59358,55.595708],
        [37.585109,55.595511],
        [37.593756,55.593686],
        [37.587359,55.589432],
        [37.587308,55.58813],
        [37.588594,55.588029],
        [37.58906,55.588921],
        [37.590071,55.588955],
        [37.590246,55.589583],
        [37.590854,55.589623],
        [37.590771,55.588852],
        [37.591782,55.588647],
        [37.593917,55.588432],
        [37.593828,55.587272],
        [37.595459,55.58663],
    ],
    8: [],
    9: [
        [37.594346,55.583562],
        [37.594399,55.583686],
        [37.593832,55.583997],
        [37.593545,55.583822],
        [37.590946,55.584265],
        [37.590405,55.584339],
        [37.58994,55.584321],
        [37.589833,55.582764],
        [37.589775,55.581113],
        [37.586003,55.581207],
        [37.585351,55.5814],
        [37.585694,55.582196],
        [37.584849,55.58256],
        [37.584573,55.582864],
        [37.583841,55.583034],
        [37.583316,55.583453],
        [37.583488,55.583678],
        [37.582673,55.58398],
        [37.5816,55.583975],
        [37.580763,55.584383],
        [37.580473,55.584493],
        [37.580398,55.58473],
        [37.579063,55.584776],
        [37.578253,55.584791],
        [37.57822,55.583135],
        [37.575221,55.582438],
        [37.574999,55.582123],
        [37.575789,55.581947],
        [37.577491,55.581625],
        [37.581534,55.5812],
        [37.582973,55.580614],
        [37.585172,55.579153],
        [37.588032,55.577913],
        [37.592026,55.577651],
        [37.594539,55.578489],
        [37.59664,55.580525],
        [37.597282,55.583217],
        [37.594346,55.583562],
    ],
    10: [
        [37.589489,55.642504],
        [37.589805,55.638936],
        [37.588942,55.636054],
        [37.587675,55.632637],
        [37.590121,55.632133],
        [37.592428,55.632643],
        [37.593758,55.632643],
        [37.596568,55.633602],
        [37.601782,55.634488],
        [37.601419,55.634961],
        [37.60282,55.636034],
        [37.602998,55.636561],
        [37.604256,55.636594],
        [37.604925,55.637947],
        [37.605262,55.639883],
        [37.60526,55.641309],
        [37.589489,55.642504],
    ],
}


const data = [
    ZONES[1],
    ZONES[2],
    ZONES[3],
    ZONES[4],
    ZONES[5],
    ZONES[10],
]

const polygonData = data.map((arr) => arr.map(([lon, lat]) => [lat, lon]))

export const options = {
    fillColor: colors.accent,
    strokeColor: colors.accent,
    opacity: 0.25,
    strokeWidth: 2,
    // strokeStyle: "shortdash",
}

export default polygonData
