import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import clsx from 'clsx'
import { defaultTags } from '../../array/preferencesData'
import TopPage from '../../components/top-page'
import { getTags, setTag } from '../../query/tags'
import { GROUP_CATALOG_LISTING } from '../../constants/cache'
import useLayout from '../../util/layout'
import emptyImg from '../../img/empty/tag.png'
import './preferences.scss'

const listDefaultTags = defaultTags.map((item, index) => ({ ...item, tagId: index, isChecked: false }))

const Item = ({ image, name = '', id, checked = false, isDefault = false, onChange }) => {
  const onClick = useCallback(() => onChange(id, !checked), [onChange, id, checked])
  return (
    <div className={clsx('preference', checked && '_active', isDefault && '_loadData')} onClick={onClick}>
      <div className="preference__icon">
        <img src={image || emptyImg} alt=""/>
      </div>
      <div className="preference__name">{name}</div>
    </div>
  )
}

const Preferences = () => {
  useLayout()
  const history = useHistory()
  const [preferences, setPreferences] = useState(listDefaultTags)
  const [exclude, setExclude] = useState(listDefaultTags)

  useEffect(() => {
    getTags((response) => {
      if(response.preferences) {
        setPreferences(response.preferences);
      }
      if(response.exclude) {
        setExclude(response.exclude);
      }
    }, () => {}, { useCache: true })
  }, [])

  const onChange = useCallback((id, isChecked = false) => {
    setTag({ tagId: id, status: isChecked ? 1 : '' }, (response)=>{
      if(response.preferences) {
        setPreferences(response.preferences);
      }
      if(response.exclude) {
        setExclude(response.exclude);
      }
    }, () => {}, { replaceCacheURL: '/tags', clearCache: { group: GROUP_CATALOG_LISTING } })
  }, [])

  return (
    <div className="preferences layout">
      <div className="layout__top layout__top--gray">
        <TopPage
          onClick={history.goBack}
          title="Предпочтения"
        />
      </div>
      <div className="layout__body">
        <div className="grid-inner">
          {preferences.length ? (
            <div className="preferences__block">
              <p className="preferences__block-caption">Диета</p>
              <div className="preferences__block-content">
                {preferences.map((item) => (
                  <div key={item.tagId} className="preferences__item preferences__item--big">
                    <Item
                      id={item.tagId}
                      image={item.image}
                      name={item.name}
                      checked={item.isChecked}
                      onChange={onChange}
                      isDefault={item.default}
                    />
                  </div>
                ))}
              </div>
            </div>
          ) : null}

          {exclude.length ? (
            <div className="preferences__block">
              <p className="preferences__block-caption">Исключить</p>
              <div className="preferences__block-content">
                {exclude.map((item) => (
                  <div key={item.tagId} className="preferences__item">
                    <Item
                      id={item.tagId}
                      image={item.image}
                      name={item.name}
                      checked={item.isChecked}
                      onChange={onChange}
                      isDefault={item.default}
                    />
                  </div>
                ))}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default Preferences

{/* {preferences.map((item, index) => (
                    <Form.Group key={index} className={"card-item " + (item.default ? 'loadData' : '')} controlId={item.tagId}>
                      <img src={item.image} alt=""/>

                      <div className={"card-item__name form-check"}>
                          <Form.Check.Input
                              onChange={onChange(item.tagId)}
                              checked={item.isChecked}
                              id={item.tagId}
                          />

                          <Form.Check.Label htmlFor={item.tagId} className="text">
                              {item.name}
                          </Form.Check.Label>
                      </div>
                    </Form.Group>
                  ))} */}
