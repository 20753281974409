import React, { useCallback, useState } from 'react'
import clsx from 'clsx'
import Onboarding from '../index'
import Modal from '../../modal'
import './styles.scss'

const OnboardingBtn = ({ mods = '' }) => {
  const [visible, toggleVisible] = useState(false)
  const onShow = useCallback(() => toggleVisible(true), [])
  const onHide = useCallback(() => toggleVisible(false), [])

  return (
    <>
    <div className={clsx('onboarding-btn', mods)} onClick={onShow}>
      <div className="onboarding-btn__inner">
        <div className="onboarding-btn__icon" />
        <div className="onboarding-btn__content">
          <p className="onboarding-btn__title">Что такое Тарелка?</p>
          <p className="onboarding-btn__subtitle">Посмотрите короткое видео</p>
        </div>
      </div>
    </div>
    <Modal
      visible={visible}
      onClose={onHide}
      title=''
      mod="onboarding"
    >
      <Onboarding title="Как работает Тарелка?" autoPlay={true} />
    </Modal>
    </>
  )
}

export default OnboardingBtn
