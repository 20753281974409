import API from "../api";
import {useReducer} from "../util/store";
const [alert, setAlert] = useReducer('alert');

export const getCart = (callback, failCallback = () => {}, config = {}) => {
    API.get(
        '/cart',
        function (response) {
            if(response.message){
                setAlert({danger: response.message});
                failCallback(response);
            } else {
                callback(response);
            }
        },
        function (error) {
            failCallback(error);
        },
        config
    );
}

export const setCart = (data, callback, failCallback = () => {}, config = {}) => {
    API.post(
        '/cart',
        data,
        function (response) {
            if(response.message){
                setAlert({danger: response.message});
                failCallback(response);
            } else if(response.products) {
                callback(response);
            }
        },
        function (error) {
            failCallback(error);
        },
        config,
    );
}

const getQueryForCheckAvailability = (products = []) => {
  const result = {}
  products.forEach((item, index) => {
    result[`products[${index}][productId]`] = item.productId
    result[`products[${index}][quantity]`] = item.quantity
    item.options.forEach((option, i) => {
      result[`products[${index}][optionsValues][${i}][optionValueId]`] = option.dict_option_value_id
      result[`products[${index}][optionsValues][${i}][quantity]`] = option.quantity
    })
  })

  const searchParams = new URLSearchParams(result)

  return searchParams.toString()
}


export const checkAvailabilityByProducts = (products = [], callback, failCallback = () => {}, config = {}) => {
  const query = getQueryForCheckAvailability(products)

  API.get('/products/checkAvailability?' + query,
    function (response) {
      if(response.message){
        setAlert({danger: response.message});
        failCallback(response);
      } else {
        callback(response);
      }
    },
    function (error) {
      failCallback(error);
    },
    config
  );
}
