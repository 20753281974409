import React from 'react'
import IconArrow from '../../img/icons/arrow-right.svg'
import './styles.scss'

const SelectLink = ({
  onClick,
  children,
}) => (
  <div className="select-link" onClick={onClick}>
    <div className="select-link__content">{children}</div>
    <div className="select-link__arrow">
      <IconArrow />
    </div>
  </div>
)

export default SelectLink
