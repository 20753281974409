import React, {useCallback, useEffect, useMemo, useState} from 'react';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useReducer } from "../../util/store";
import { getGroups, setFilter } from "../../query/tags";
import useChatBtn from "../../util/chat";
import declOfNum from "../../util/decl-of-num";
import { getCounterActiveOrders } from "../../store/actions/cart";
import { activeOrderCountSelector } from "../../store/selectors/cart";
import { GROUP_CATALOG_LISTING } from "../../constants/cache";
import Modal from "../modal";
import Filters from "../filters";
import Logo from "../logo";
import HeaderBtn from "./btn";
import IconUser from './icons/user'
import IconChat from './icons/chat'
import IconFilter from './icons/filter'
import './styles.scss'

const getSelectedTagsFromGroups = (groups = []) => {
  const result = []

  groups.forEach(({ tags = [] }) => {
    tags.forEach((item) => {
      if (item.isChecked) {
        result.push(item)
      }
    })
  })

  return result
}

const Header = ({
  isVisibleProductList = false,
  count = 0,
  note = '',
}) => {
  const dispatch = useDispatch()
  const activeOrderCount = useSelector(activeOrderCountSelector)
  const history = useHistory()
  const chatBtn = useChatBtn()
  const [visibleFilters, toggleVisibleFilters] = useState(false)
  const onShowFilters = useCallback(() => toggleVisibleFilters(true), [])
  const onHideFilters = useCallback(() => toggleVisibleFilters(false), [])

  const [tagGroups, setTagGroups] = useState([]);
  const [selected, setSelected] = useReducer('filter', 'Header', []);
  const filtersCount = useMemo(() => selected.length, [selected.length])
  const [isLoading, toggleLoading] = useState(false)

  useEffect(()=>{
    getGroups((res) => {
      setTagGroups(res)
      setSelected(getSelectedTagsFromGroups(res))
    });
    dispatch(getCounterActiveOrders())
  }, [dispatch])

  const onChangeTag = useCallback((tagId, status) => {
    toggleLoading(true)
    setFilter({tagId: tagId, status: status},
      (response)=>{
        if(response.tags) setSelected(response.tags);
        toggleLoading(false)
      },
      () => toggleLoading(false),
      { clearCache: { group: GROUP_CATALOG_LISTING } }
    )
  }, [])

  const onClickProfile = useCallback(() => history.push('/Lk'), [history])
  const btnModalFilters = useMemo(() => {
    return {
      text: isVisibleProductList ? `Показать ${count} ${declOfNum(count, 'plate')}` : 'Показать',
      onClick: onHideFilters,
    }
  }, [onHideFilters, isVisibleProductList, count])

  return (
    <>
    <header className="header">
      <div className="grid-inner">
        <div className="header__body">
          <div className="header__block header__block--left">
            <HeaderBtn onClick={onClickProfile} active={!!activeOrderCount} count={activeOrderCount}>
              <IconUser />
            </HeaderBtn>
          </div>
          <div className="header__block header__block--logo">
            <Logo isAnim />
          </div>
          <div className="header__block header__block--right">
            {!chatBtn.isLoaded ? (
              <HeaderBtn onClick={chatBtn.onClick}>
                <IconChat />
              </HeaderBtn>
            ) : null}
            <HeaderBtn onClick={onShowFilters} active={filtersCount > 0} count={filtersCount}>
              <IconFilter />
            </HeaderBtn>
          </div>
        </div>
        {note ? (
          <p className="header__notes onboarding-notes" dangerouslySetInnerHTML={{ __html: note }} />
        ) : null}
      </div>
    </header>
    <Modal
      visible={visibleFilters}
      onClose={onHideFilters}
      btn={btnModalFilters}
    >
      <Filters
        selected={selected}
        groups={tagGroups}
        onChange={onChangeTag}
        disabled={isLoading}
      />
    </Modal>
    </>
  )
}

export default Header;
