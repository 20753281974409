import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import './styles.scss'

const ErrorBlock = ({
  children = '',
  code = ''
}) => {
  const history = useHistory()
  const onGoToCatalog = useCallback(() => {
    history.push('/Catalog')
  }, [])

  return (
    <div className="error-block">
      <div className="error-block__body">
        <div className="grid-inner">
          <p className="error-block__code">{code}</p>
          <div className="error-block__text">{children}</div>
          <div className="error-block__btn">
            <Button onClick={onGoToCatalog} variant="primary">Перейти в каталог</Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ErrorBlock
