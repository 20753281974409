import React from 'react'
import ErrorBlock from '../../components/error-block'

const Page404 = () => {

  return (
    <div className="layout">
      <ErrorBlock
        code="404"
      >
        <p>Страница не найдена</p>
      </ErrorBlock>
    </div>
  )
}

export default Page404
