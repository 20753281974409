import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import Modal from '../modal'
import Input from '../input/index'
import { setComment } from '../../store/actions/order'
import { MAX_COUNT } from '../../constants/comment'

const ModalComment = ({
  visible = false,
  onClose = () => {},
  orderComment = ''
}) => {
  const dispatch = useDispatch()
  const [localComment, setLocalComment] = useState(orderComment)

  const onChangeComment = useCallback((e) => {
    const value = e.target.value.length >= MAX_COUNT ? e.target.value.slice(0, MAX_COUNT) : e.target.value

    setLocalComment(value)
  }, [dispatch])

  const onSaveComment = useCallback((e) => {
    e?.preventDefault()
    dispatch(setComment(localComment))
    onClose()
  }, [dispatch, localComment, onClose])

  const onCloseComment = useCallback(() => {
    setLocalComment(orderComment)
    onClose()
  }, [dispatch, orderComment, onClose])

  return (
    <Modal
      visible={visible}
      onClose={onCloseComment}
      title="Пожелания к заказу"
      mod="cart"
      onSubmit={onSaveComment}
      btn={{ text: 'Сохранить', onClick: onSaveComment }}
    >
      <Input
        autoFocus
        onChange={onChangeComment}
        value={localComment}
        placeholder="Добавьте комментарий"
        id="comment"
        isTextarea
        error={localComment.length >= MAX_COUNT ? `Макс. кол-во символов: ${MAX_COUNT}` : null}
      />
    </Modal>
  )
}

export default ModalComment
