export const getFormattedPrice = (sum = 0) => (
  sum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
)

export const roundedNum = (num, isRounding = false, count = 2) => {
  if (isRounding) {
    return Math.floor(num)
  }
  return Math.round(num * Math.pow(10, count)) / Math.pow(10, count)
}
