import React, { useMemo } from 'react'
import clsx from 'clsx'
import OrderProduct from '../order-product'
import IconProgress1 from '../../img/order-progress/new/progress-1.svg'
import IconProgress2 from '../../img/order-progress/new/progress-2.svg'
import IconProgress3 from '../../img/order-progress/new/progress-3.svg'
import IconProgress4 from '../../img/order-progress/new/progress-4.svg'
import { stepsUIByStatuses } from '../../constants/statuses'
import './styles.scss'

const listSteps = [
  { step: 1, Icon: IconProgress1 },
  { step: 2, Icon: IconProgress2 },
  { step: 3, Icon: IconProgress3 },
  { step: 4, Icon: IconProgress4 },
]

const OrderAdditionalInfo = ({ data }) => {
  const isCancelStatus = useMemo(() => data.statusId > 6, [data.statusId])
  const currentStep = useMemo(() => {
    if (isCancelStatus) {
      return 0
    }

    return stepsUIByStatuses[data.statusId]
  }, [isCancelStatus, data.statusId])

  const style = useMemo(() => {
    const size = currentStep ? 33 * Math.max(currentStep - 1, 0) : 0
    return { width: `calc(${Math.min(size, 100)}% + 13px)` }
  }, [currentStep])

  return (
    <div className="orders-card__dropdown">
      {!isCancelStatus ? (
        <div className="order-progress" data-status={currentStep}>
          {listSteps.map(({ step, Icon }) => (
            <div className="order-progress__item" key={step}>
                <div className={clsx('order-progress__img', currentStep >= step && 'active', `order-progress__img--${step}`)}>
                    <Icon />
                </div>
            </div>
          ))}
          <div className="order-progress__line" style={style}/>
        </div>
      ) : null}

      {data.statusComment ? (
        <p className="order-status-comment text">{data.statusComment}</p>
      ) : null}

      <div className="orders-card__products">
        {data.products.map((product, index) => (
          <OrderProduct data={product} quantity={product.quantity} key={`${product.product_id}_${index}`} type="order"/>
        ))}
      </div>
    </div>
  )
}

export default OrderAdditionalInfo
