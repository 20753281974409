import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setCutlery } from '../../store/actions/order'
import { cutlerySelector } from '../../store/selectors/order'
import { getFormattedPrice } from '../../util/number'
import Count from '../count'
import cutleryImg from '../../img/cutlery-big.png'
import './styles.scss'

// TODO:
const PRICE_SINGLE_CUTLERY = 0

const Cutlery = () => {
  const quantity = useSelector(cutlerySelector);
  const dispatch = useDispatch()
  const onChange = useCallback((isAdd = false) => dispatch(setCutlery(isAdd)), [dispatch])
  const renderPrice = useMemo(() => `${getFormattedPrice(PRICE_SINGLE_CUTLERY * quantity)} ₽`)

  return (
    <div className="cutlery">
      <div className="cutlery__inner">
        <div className="cutlery__img">
        <img src={cutleryImg} alt="" />
        </div>
        <div className="cutlery__content">
          <p className="cutlery__name">Приборы</p>
          <p className="cutlery__price">{renderPrice}</p>
        </div>
        <div className="cutlery__controls">
          <Count onChange={onChange} quantity={quantity} />
        </div>
      </div>
    </div>
  )
}

export default Cutlery
