import React from 'react'

const Filter = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21 7L11 7" stroke="#414C58" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <circle cx="2" cy="2" r="2" transform="matrix(-1 0 0 1 7 5)" fill="#414C58" stroke="#414C58" strokeWidth="1.5"/>
      <path d="M3 17L13 17" stroke="#414C58" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <circle cx="19" cy="17" r="2" fill="#414C58" stroke="#414C58" strokeWidth="1.5"/>
    </svg>
  )
}

export default Filter
