import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { getListArticles } from '../../query/article'
import TopPage from '../../components/top-page'
import LinkBtn from '../../components/link-btn'
import useLayout from '../../util/layout'
import './styles.scss'

const defaultList = [1,2,3,4,5,6,7,8,9,10]

const Pages = () => {
    useLayout()
    const history = useHistory()
    const [data, setData] = useState([]);
    const [isLoading, setLoading] = useState(true)

    useEffect(()=>{
        setLoading(true)
        getListArticles((res) => {
            setLoading(false)
            setData(res)
        })
    }, []);

    return (

        <div className="text-page layout">
            <div className="layout__top layout__top--gray">
                <TopPage
                    onClick={history.goBack}
                    title="Страницы"
                />
            </div>
            <div className="layout__body">
                <div className="grid-inner">
                    <div className={`text-page__body text-page__body--list ${isLoading ? 'loadData' : ''}`}>
                        {isLoading ? (
                            defaultList.map((val) => <p key={val} className="text"><br/><br/></p>)
                        ) : (
                            data.map(({ information_id: id, title }) => (
                                <LinkBtn to={`/pages/${id}`} mod="text-page__list-item" key={id}>
                                    {title}
                                </LinkBtn>
                            ))
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Pages
