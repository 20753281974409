export const getOptions = (options = [], invalidList = []) => {
  const result = {
    valid: [], invalid: []
  }

  options.forEach((item) => {
    if (invalidList.some(({ id }) => id === item.dict_option_value_id)) {
      result.invalid.push(item)
    } else {
      result.valid.push(item)
    }
  })

  return result
}
