import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import _throttle from 'lodash/throttle'
import IconLogo from '../../img/logo.svg'
import './styles.scss'

const Logo = ({ isAnim = false }) => {
  const [active, setActive] = useState(true)
  const refScrollTop = useRef(0)
  const refActive = useRef(true)

  useEffect(() => {
    if (!isAnim) {
      return
    }

    const toggle = () => {
      const prevScrollTop = refScrollTop.current
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop
      // Показываем лого в первых 80px или если скролим вверх
      const value = scrollTop <= 80 || (scrollTop < prevScrollTop)
      refScrollTop.current = scrollTop
      if (refActive.current !== value) {
        setActive(value)
        refActive.current = value
      }
    }

    const toggleThrottle = _throttle(toggle, 100)

    toggle()
    window.addEventListener('scroll', toggleThrottle)
    window.addEventListener('resize', toggleThrottle)
    return () => {
      window.removeEventListener('scroll', toggleThrottle)
      window.removeEventListener('resize', toggleThrottle)
    }
  }, [isAnim])

  return (
    <Link className={clsx('logo-btn', active && '_active')} to="/Catalog">
      <IconLogo />
    </Link>
  )
}

export default Logo
