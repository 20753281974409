import React from 'react'
import clsx from 'clsx'

const WarningMessage = ({ text = [], mods }) => {

  return (
    <div className={clsx('message', 'message--info', 'message--static', mods)}>
      <div className="message__icon" />
      <div className="message__text">
        {text.map((val, index) => (
          <p key={index} dangerouslySetInnerHTML={{ __html: val }} />
        ))}
      </div>
    </div>
  )
}

export default WarningMessage
