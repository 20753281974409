import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react'
import OrderProduct from '../order-product'
import Count from '../count'
import IconTrash from '../../img/icons/cart/trash.svg'
import './styles.scss'

const CartProduct = ({
  data: product,
  cartData,
  setCartData,
  error = {},
}) => {
  const timer = useRef(null)
  const [quantity, setQuantity] = useState(product.quantity);

  useEffect(()=>{
    let qty = cartData.products.find(item => item.productId === product.id);
    if(typeof qty !=='undefined' && quantity !== qty.quantity){
      setQuantity(qty.quantity);
    }
  }, []);

  const changeQuantity = useCallback((plus, isDelete = false) => {
    const qty = isDelete ? 0 : Math.max(plus ? quantity + 1 : quantity - 1, 0);
    setQuantity(qty);

    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      setCartData({products: [{
        cartId: product.cartId,
        productId: product.productId,
        quantity: qty
      }]})
    }, 200);
  }, [quantity, timer, product.cartId, product.productId])

  const onDelete = useCallback(() => changeQuantity(false, true), [])

  const isFullDisabled = useMemo(() => (
    error?.statuses?.isFullDisabled
  ), [error])
  const isPartDisabled = useMemo(() => (
    error?.statuses?.isPartDisabled || error?.statuses?.isDisabledMods
  ), [error])

  return (
    <OrderProduct messages={error?.messages} data={product} quantity={quantity} type="cart" isFullDisabled={isFullDisabled} isPartDisabled={isPartDisabled} error={error}>
      {isFullDisabled ? (
        <div className="cart-product__trash" onClick={onDelete}><IconTrash /></div>
      ) : (
        <Count onChange={changeQuantity} quantity={quantity} disabledAdd={isPartDisabled} />
      )}
    </OrderProduct>
  )
}

export default CartProduct
