import React, { useState, useEffect, useMemo } from 'react'
import { useLocation, Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'react-bootstrap'
import clsx from 'clsx'
import Header from '../../components/header'
import { clearOrder } from '../../store/actions/order'
import useBodyMod from '../../util/body-mod'
import useLayout from '../../util/layout'
import { getOrderStatus, getOrderPaymentStatus, getLastOrderPromise } from '../../query/orders'
import './styles.scss'

const getStatusOrder = (fakeData = {}) => new Promise((resolve, reject) => {
  setTimeout(() => {
    resolve(fakeData)
  }, 100)
})

const CancelBlock = ({ orderId }) => {
  const [cancelOrder, setCancelOrder] = useState(false)

  useEffect(() => {
    if (orderId) {
      const func = async () => {
        try {
          const res = await getStatusOrder({ time: 60 })
          if (res.time) {
            setCancelOrder({ time: res.time })
            return
          }

          setCancelOrder(false)
        } catch (error) {
          console.log(error)
        }
      }

      func()
    } else {
      setCancelOrder(false)
    }
  }, [orderId])

  useEffect(() => {
    let timer = null
    if (cancelOrder.time && cancelOrder.time > 0) {
      timer = setTimeout(() => setCancelOrder(({ time }) => {
        const newTime = time - 1
        return newTime === 0 ? false : { time: newTime }
      }), 1000)
    }
    return () => clearTimeout(timer)
  }, [cancelOrder])

  const renderTime = useMemo(() => {
    if (!cancelOrder?.time) {
      return null
    }
    return `00:${cancelOrder.time.toString().padStart(2, '0')}`
  }, [cancelOrder?.time])

  if (!cancelOrder) {
    return null
  }

  return (
    <div>
      <p className="order-accepted__text">
        Заказ можно отменить в течение <br/>
        <span className="order-accepted__timer">{renderTime}</span>
      </p>

      <Button onClick={() => {}} variant="gray-border width-auto">Отменить заказ</Button>
    </div>
  )
}

const OrderAccepted = () => {
  useLayout()
  useBodyMod('body-white')
  const dispatch = useDispatch()
  const [isLoading, toggleLoading] = useState(true)
  const [orderId, setOrderId] = useState(null)
  const [orderStatus, setOrderStatus] = useState({
    name: '',
    payment: '',
  })
  const location = useLocation()

  useEffect(() => {
    if (location.search.length === 0) {
      return
    }

    const getStatus = async (orderId) => {
      setOrderId(orderId)
      const res = await getOrderStatus(orderId)
      const res2 = await getOrderPaymentStatus(orderId)
      if (res.code === 404) {
        setOrderStatus({
          name: res.message,
          payment: null,
        })
        toggleLoading(false)
        return
      }

      // Заказ оплачен
      if (`${res2?.code}` === '2') {
        dispatch(clearOrder())
      }
      setOrderStatus({
        name: res.name || res.message,
        payment: res2.message,
      })

      toggleLoading(false)
    }

    const func = async () => {
      try {
        toggleLoading(true)
        const params = new URLSearchParams(location.search)
        const orderId = params.get('order_id') || null
        if (orderId) {
          getStatus(orderId)
          return
        }

        const res = await getLastOrderPromise()
        if (res?.orderId) {
          getStatus(res.orderId)
          return
        }
      } catch (error) {
        console.log(error)
        toggleLoading(false)
      }
    }

    func()
  }, [location.search, dispatch])

  return (
    <div className="layout">
      <div className="layout__top">
        <Header />
      </div>
      <div className="layout__body order-accepted__body">
        <div className="grid-inner">
          <div className="order-accepted__content">
            <p className={clsx('order-accepted__icon', isLoading && 'loadData')}>👋</p>
            {!isLoading ? (
              <div>
                {orderId ? <h2 className="order-accepted__title">Заказ №{orderId}</h2> : null}
                {orderStatus.name ? <p className="order-accepted__subtitle">{orderStatus.name}</p> : null}
                {orderStatus.payment ? <p className="order-accepted__subtitle">{orderStatus.payment}</p> : null}

              </div>
            ) : null}
            <Link className="btn btn-primary" to="/Catalog">В каталог</Link>
            <div className="order-accepted__tg">
              <p className="order-accepted__subtitle">Новости, акции и сообщество «Тарелки» в <a href="https://t.me/tarelkavv" target="_blank">телеграм-канале тут</a></p>
            </div>
            {/* TODO: пока скрыто */}
            {/* {orderId ? (
              <CancelBlock orderId={orderId}/>
            ) : null} */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrderAccepted
