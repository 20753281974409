import { useEffect } from 'react'

const useBodyMod = (className = '') => {
  useEffect(() => {
    document.body.classList.add(className)

    return () => document.body.classList.remove(className)
  }, [className])
}

export default useBodyMod
