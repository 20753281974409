import { createSlice } from '@reduxjs/toolkit'
import { logoutUser } from '../actions/user'
import { clearCart } from '../actions/cart'

const initialState = {
  comment: '',
  cutlery: 1,
}

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setComment(state, action) {
      state.comment = action.payload
    },
    clearOrder() {
      return initialState
    },
    setCutlery(state, action) {
      const oldValue = state.cutlery
      const newValue = Math.max(action.payload ? oldValue + 1 : oldValue - 1, 0) || 0
      state.cutlery = newValue
    },
  },
  extraReducers: builder => {
    builder.addCase(logoutUser, () => {
      return initialState
    })
    builder.addCase(clearCart, () => {
      return initialState
    })
  },
})

export const { setComment, setCutlery, clearOrder } = orderSlice.actions
export default orderSlice.reducer
