import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import Order from '../../components/order'
import TopPage from '../../components/top-page'
import EmptyBlock from '../../components/empty-block'
import {defaultOrders, getOrders} from '../../query/orders'
import { getCounterActiveOrders } from '../../store/actions/cart'
import { activeOrderCountSelector } from '../../store/selectors/cart'
import useLayout from '../../util/layout'
import './orders.scss'

const OrderListPage = () => {
  useLayout()
  const dispatch = useDispatch()
  const activeOrderCount = useSelector(activeOrderCountSelector)
  const history = useHistory()
  const [orders, setOrders] = useState(defaultOrders)
  const [page, setPage] = useState(0)

  useEffect(()=>{
    dispatch(getCounterActiveOrders())
  }, [dispatch])

  useEffect(()=>{
    getOrders(page, setOrders)
  }, [page])

  return (
    <div className="orders-page layout">
      <div className="layout__top layout__top--gray">
        <TopPage
          onClick={history.goBack}
          title="Мои заказы"
        />
      </div>

      <div className="layout__body">
        <div className="grid-inner">
          <div className={clsx('orders-active', !activeOrderCount && '_disabled')}>
            <div className="orders-active__title">{activeOrderCount ? 'Активные заказы' : 'Активных заказов нет'}</div>
            {activeOrderCount ? <div className="orders-active__count"><span>{activeOrderCount}</span></div> : null}
          </div>

          {!orders.length ? (
            <div className="orders-page__empty">
              <EmptyBlock>
                <p className="bold">Заказов пока нет</p>
              </EmptyBlock>
            </div>
          ) : null}

          {orders.map((order) => (
            <Order key={order.orderId} order={order} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default OrderListPage
