
const originalText = {
  'Tarelki-ot-Shefa': 'Выбирайте из готовых вариантов',
  'Tarelki-konstruktor': 'Собирайте свою Тарелку',
}

const getTextsNotes = (texts) => {
  const res = {}

  Object.entries(texts).forEach(([key, value]) => {
    res[key.toLowerCase()] = value
  })

  return res
}

const textsNotes = getTextsNotes(originalText)

const getTextCategory = (str = '') => {
  const key = str.toLowerCase()

  return textsNotes[key] || ''
}

export default getTextCategory
