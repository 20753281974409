import React, { useCallback, useState } from 'react'
import {useReducer} from '../../util/store'
import Modal from '../modal'
import Input from '../input/index'

const PromoCode = ({
  setCartData,
  visible = false,
  onClose = () => {},
}) => {
  const [promoCode, setPromoCode] = useState({ value: '', isError: false })
  const [alert, setAlert] = useReducer('alert');

  const setError = useCallback(() => setPromoCode((prev) => ({ ...prev, isError: true })), [])

  const onSubmit = useCallback(async (e) => {
    e.preventDefault()
    const value = promoCode.value.trim()
    if (value.length === 0) {
      return setError()
    }

    try {
      const res = await setCartData({promocode: value}, true)
      if(res.promocode){
        onClose()
        setPromoCode({ value: '', isError: false })
      }else{
        setError()
        setAlert({'info':'Промокод не найден или не выполнены условия применения!'})
      }
    } catch (error) {
      setError()
      console.log(error)
    }
  }, [setCartData, onClose, promoCode, setError])

  const onChange = useCallback((e) => setPromoCode({ value: e.target.value, isError: false }), [])

  return (
    <Modal
      visible={visible}
      onClose={onClose}
      title="Промокод"
      mod="cart"
      btn={{ text: 'Применить', onClick: onSubmit }}
    >
      <form onSubmit={onSubmit}>
        <Input
          autoFocus
          onChange={onChange}
          value={promoCode.value}
          placeholder="Введите ваш промокод"
          error={promoCode.isError}
          id="promoCode"
        />
      </form>
    </Modal>
  )
}

export default PromoCode
