import { useRef, useCallback, useEffect, useState, useMemo } from 'react'
import { KEY_CHAT } from '../../constants/chat'

const useChatBtn = () => {
  const block = useRef(null)
  const isClickedQuestion = useRef(false)
  const [isLoaded, setLoaded] = useState(false)

  useEffect(() => {
    const assistLineScript = document.getElementById('assist-line-script');
    if (assistLineScript) {
      return
    }

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = 'assist-line-script';
    script.async = true;
    script.src = `https://assist-line.com/chat.js?tlgchat_id=${KEY_CHAT}&category=&subcategory=`;
    script.onload = () => {
      setLoaded(true)
    }
    const s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(script, s);
  }, [])

  const onClick = useCallback(() => {
    if (!block.current) {
      block.current = document.getElementById('chat-open-close-t')
    }

    block.current?.click()
  }, [block])

  const onShowChatAndClickQuestion = useCallback((question = '') => {
    if (!block.current) {
      block.current = document.getElementById('chat-open-close-t')
    }

    block.current?.click()

    if (question && !isClickedQuestion.current) {
      const elements = document.querySelectorAll('#list-questions-t .questions-link-t')
      for (let i = 0; i < elements.length; i++) {
        const link = elements[i]

        if (link.innerHTML?.trim() === question) {
          link.click()
          isClickedQuestion.current = true
        }
      }
    }
  }, [block])

  return useMemo(() => ({
    isLoaded,
    onClick,
    onShowChatAndClickQuestion,
  }), [])
}

export default useChatBtn
