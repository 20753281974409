import React, { useCallback } from 'react'
import clsx from 'clsx'
import Image from '../../image'
import emptyImg from '../../../img/empty/filter.png'
import './styles.scss'

const FilterItem = ({
  onChange = () => {},
  id = null,
  value = '',
  image = '',
  active = false,
  disabled = false,
}) => {
  const onClick = useCallback(() => {
    if (disabled) {
        return
    }
    onChange(id, !active)
  }, [id, onChange, active, disabled])

  return (
    <div className={clsx('filter-item', disabled && 'disabled', active && 'active')} onClick={onClick}>
      <div className="filter-item__icon">
        <Image src={image} alt="" emptyImg={emptyImg} />
      </div>
      <div className="filter-item__name">{value}</div>
      {active ? (
        <div className="filter-item__close" />
      ) : null}
    </div>
  )
}

export default FilterItem
