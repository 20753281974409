import API from "../api";
import { useReducer } from "../util/store";
import orderItemImg from "../img/order-item__img.png";

import progressImg1 from "../img/progress1.png";
import progressImg2 from "../img/progress2.png";
import progressImg3 from "../img/progress3.png";
import progressImg4 from "../img/progress4.png";

const [alert, setAlert] = useReducer( 'alert' );

export const defaultOrder = {
    default: true,
    orderId: 0,
    createDate: "",
    deliveryDate: "",
    total: "0",
    status: "",
    address: "",//"1-й Конной Армии, 37а, кв. 16, под. 4",
    statusClass: "",
    products: [],
}

export const defaultOrders = [
    { ...defaultOrder, orderId: 1, },
    { ...defaultOrder, orderId: 2, },
    { ...defaultOrder, orderId: 3, },
]

export const getLastOrder = (callback) => {
    API.get(
        '/order/last',
        function (response){
            if(callback && response.orderId) callback(response);
        }
    );
}

export const getLastOrderPromise = (callback) => new Promise((resolve, reject) => {
    API.get(
        '/order/last',
        resolve,
        reject,
        { isHideAlert: true },
    );
})

export const getOrders = (page, callback) => {
    API.get(
        '/orders' + (page ? '?page=' + page : ''),
        function (response){
            if(callback && response.orders) callback(response.orders);
        }
    );
}

const _getFuncOrderStatus = (status = 'status') => (id) => new Promise((resolve, reject) => {
    API.get(
        `/order/${id}/${status}`,
        resolve,
        // if(response.message){
        //     setAlert({danger: response.message})
        //     reject(response)
        // } else {
        //     resolve(response)
        // }
        reject,
        { isHideAlert: true }
    );
})

export const getOrderStatus = _getFuncOrderStatus('status')
export const getOrderPaymentStatus = _getFuncOrderStatus('paymentStatus')
