import React, { useCallback, useMemo, useState } from 'react'
import clsx from 'clsx'
import OrderAdditionalInfo from '../order-additional-info'
import formatDate from '../../util/date/format-date'
import { getFormattedPrice } from '../../util/number'
import './styles.scss'

const Order = ({ order }) => {
  const [open, setOpen] = useState(false);
  const onToggle = useCallback(() => setOpen((prev) => !prev), [])

  const formattedCreateDate = useMemo(() => {
    if (!order.createDate) {
      return ''
    }

    return formatDate(order.createDate)
  }, [order.createDate])

  const count = useMemo(() => (
    order.products.reduce((sum, order) => sum + order.quantity, 0)
  ), [order.products])

  return (
    <div className={clsx('orders-card', order.default && 'loadData', open && '_open')}>
      <div onClick={onToggle} className="orders-card__button">
        <div className="orders-card__row">
          <div className="orders-card__left orders-card-text orders-card-text--num text">#{order.orderId}</div>
          <div className="orders-card__right orders-card-controls">
            <div className="orders-card-text orders-card-text--price text">{getFormattedPrice(order.total)} ₽</div>
            <div className="orders-card-controls__arrow" />
          </div>
        </div>
        <div className="orders-card__row orders-card__row--center">
          <div className="orders-card__left orders-card-text text">{formattedCreateDate}</div>
          <div className="orders-card__right">
            <div className={clsx('orders-card__status', 'text', order.statusId && `_order-status-id-${order.statusId}`)}>{order.status}</div>
          </div>
        </div>
        {!open ? (
          <div className="orders-card__row">
            <div className="orders-card-text text">{order.address}</div>
          </div>
        ) : null}
      </div>

      {open ? (
        <>
          <OrderAdditionalInfo data={order} />
          <div className="orders-card__row orders-card__row--address">
            <div className="orders-card__left orders-card-text text">Адрес</div>
            <div className="orders-card__right orders-card-text text">{order.address}</div>
          </div>
          <div className="orders-card__row">
            <div className="orders-card__left orders-card-text text">Товаров</div>
            <div className="orders-card__right orders-card-text text">{count} шт</div>
          </div>
        </>
      ) : null}
    </div>
  )
}

export default Order
