import React, { useCallback } from 'react'
import { Button } from 'react-bootstrap'
import Modal from '../modal'
import API from '../../api'
import './style.scss'

const ModalLogout = ({
  visible,
  onClose
}) => {
  const onLogout = useCallback(() => API.logout(), [])
  return (
    <Modal
      visible={visible}
      onClose={onClose}
      title="Уже уходите"
      mod="cart"
    >
      <div className="modal-logout">
        <Button variant="primary" onClick={onLogout}>Ухожу</Button>
        <Button variant="dark" onClick={onClose}>Нет</Button>
      </div>
    </Modal>
  )
}

export default ModalLogout
