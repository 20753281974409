import React from 'react'
import { Route, Redirect} from 'react-router-dom'
import {useSelector } from 'react-redux'
import { isAuthSelector } from '../../store/selectors/user'

const listRedirectsGuest = [
  {
    from: ['/index.php', '/search', '/category', '/export'],
    to: { pathname: '/', search: '?non-existent' },
  },
]

const listRedirectsAuth = [
  {
    from: ['/login'],
    to: { pathname: '/Lk' },
  },
  {
    from: ['/index.php', '/subcategory', '/search', '/category', '/export', '/product'],
    to: { pathname: '/Catalog', search: '?non-existent' },
  },
]

const getRedirect = (pathname, list) => (
  list.find(({ from }) => from.some((val) => val === pathname))
)

const CustomRedirect = () => {
  const isAuth = useSelector(isAuthSelector)

  return (
    <Route
      render={props => {
        const pathname = props.location.pathname.toLowerCase()
        const redirect = getRedirect(pathname, isAuth ? listRedirectsAuth : listRedirectsGuest)

        return <Redirect to={redirect ? redirect.to : '/404'} />
      }}
    />
  )
}

export default CustomRedirect
