import API from "../api";
// import emptyImg from '../img/empty-image.png'
import emptyImg from '../img/dishes-new-468x312.jpg'

export const defaultCategory = {
    default: true,
    href: '#',
    image: emptyImg,
    name: '',
    category_id: 0
};

export const defaultCategories = [
    { ...defaultCategory, id: 1 },
    { ...defaultCategory, id: 2 },
    { ...defaultCategory, id: 3 },
    { ...defaultCategory, id: 4 },
    { ...defaultCategory, id: 5 },
]

export const getCategories = (callback, failCallback = () => {}, config = {}) => {
    API.get(
        '/menu/categories',
        function (response){
            if(callback && response.categories) callback(response.categories);
        },
        failCallback,
        config,
    );
}

export const getMenu = (callback, failCallback = () => {}, config = {}) => {
    API.get(
        '/menu/menu',
        function (response){
            if(callback && response.categories) callback(response.categories);
        },
        failCallback,
        config,
    );
}

export const getSubCategories = (alias, callback, failCallback = () => {}, config = {}) => {
    API.get(
        '/menu/category/' + alias + '/sub',
        function (response){
            if(callback && response.categories) callback(response);
            if(response.message){
                setAlert({danger: response.message});
                failCallback(response);
            }
        },
        function (error){
            failCallback(error);
        },
        config
    );
}

export const getCategory = (alias, callback, failCallback = () => {}, config = {}) => {
    API.get(
        '/menu/category/'+alias,
        function (response){
            if(response.message){
                setAlert({danger: response.message});
                failCallback(response);
            } else {
                callback(response);
            }
        },
        function (error){
            failCallback(error);
        },
        config
    );
}
