import React, { Fragment, forwardRef, useMemo } from 'react'
import { useSelector } from 'react-redux'
import ProductCard from '../product-card'
import { cartInfoSelector } from '../../store/selectors/cart'
import './styles.scss'

const getGroups = (products, categories) => {
    const isDefault = !!products[0].defaultProduct

    // Скелетон при загрузке
    if (isDefault) {
        return [{
            category: { id: 'defaultCategory' },
            products,
        }]
    }

    // Частный случай, если список якорей пуст, например, раздел `Все`
    if (categories.length == 0) {
        return [{
            category: { id: 'empty-category' },
            products,
        }]
    }

    const addProducts = {}
    // Собираем группы с продуктами
    const groups = categories.map((item) => {
        const newProducts = products.filter(({ categoryIds }) => categoryIds.some((val) => `${val}` === `${item.id}`))
        newProducts.forEach(({ id }) => {
            addProducts[id] = true
        })

        return {
            category: item,
            products: newProducts
        }
    })

    const addProductsKeys = Object.keys(addProducts)

    // Если кол-во добавленных совпадает с кол-вом исходных продуктов,
    // то это значит что нет товаров без группы, поэтому возвращаем группы как есть
    if (addProductsKeys.length === products.length) {
        return groups
    }

    // Иначе добавляем в конец списка категорию для товаров без якоря
    const emptyGroup = {
        category: { id: 'empty-category' },
        products: []
    }

    // Добавляем только те товары, которые не добавлены ни в одну из групп
    emptyGroup.products = products.filter(({ id }) => !groups.some(({ products: items }) => items.some(({ id: idx }) => idx === id)))
    groups.push(emptyGroup)
    return groups
}

const ProductList = forwardRef(({
    data,
    categories
}, ref) => {
    const groups = useMemo(() => getGroups(data, categories), [categories, data])
    const cartData = useSelector(cartInfoSelector)

    return (
        <div className="product-list" ref={ref}>
            {groups.map(({ category, products }, index) => (
                <Fragment key={`${category.id}__${index}`}>
                    {category ? (
                        <div
                            className="product-list__caption text"
                            data-section-id={category.alias}
                            key={category.id}
                        >{category.name}</div>
                    ) : null}
                    {products.map((product) => (
                        <div className="product-list__item" key={product.id}>
                            <ProductCard data={product} cartData={cartData} />
                        </div>
                    ))}
                </Fragment>
            ))}
        </div>
    );
});

export default ProductList
