import React, { useCallback, useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import useOutsideAlerter from "../../util/outside-alerter"
import './styles.scss'

const PriceDetails = ({
  basePrice = 0,
  addPrice = 0,
}) => {

  return (
    <div className="price-details">
      <div className="price-details__block price-details__block--left" />
      <div className="price-details__block price-details__block--center">
        <p className="price-details__text">
          Стоимость тарелки {basePrice}₽ {addPrice ? <span className="price-details__price-mods">+ {addPrice}₽</span> : null}
        </p>
      </div>
      <div className="price-details__block price-details__block--right">
        <Tooltip basePrice={basePrice}/>
      </div>
    </div>
  )
}

const TooltipBody = ({ onHide, refParent, basePrice }) => {
  const [show, setShow] = useState(false)
  useOutsideAlerter(refParent, onHide)

  useEffect(() => {
    setShow(true)
  }, [])

  return (
    <div className={clsx('price-details-tooltip__body', show && '_active')} onClick={onHide}>
      <div className="price-details-tooltip__arrow" />
      <p className="price-details-tooltip__text">Стоимость Тарелки - {basePrice} рублей, а&nbsp;стоимость еды на Тарелке - очень вкусная. Выбирайте наполнение на свое усмотрение</p>
    </div>
  )
}


const Tooltip = ({ basePrice }) => {
  const ref = useRef(null)
  const [data, setData] = useState({ show: false })
  const onClick = useCallback((e) => {
    e.stopPropagation()
    setData((prev) => ({ show: !prev.show }))
  }, [])
  const onHide = useCallback((e) => {
    e?.stopPropagation()
    setData({ show: false })
  }, [])

  return (
    <div className={clsx('price-details-tooltip', data.show && '_show')} ref={ref}>
      <div className="price-details-tooltip__btn" onClick={onClick} />
      {data.show ? (
        <TooltipBody onHide={onHide} refParent={ref} basePrice={basePrice}/>
      ) : null}
    </div>
  )
}

export default PriceDetails
