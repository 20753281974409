import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { logoutUser } from '../actions/user'
import { getCart, setCart } from '../../query/cart'
import { getActiveOrdersCounter } from '../../query/user'

const defaultData = {
    products: [],
    subTotal: 0,
    total: 0,
    shipping: {
        code: "flat",
        price: 0,
        toFree: 0,
        minTotal: 0
    },
    promocode: "",
    reward: null,
    payment: null,
    time: null,
    serviceError: null,
}

const initialState = {
  data: { ...defaultData },
  activeOrderCount: 0,
  status: 'none',
}

const getPromiseCounterActiveOrders = () => {
  return new Promise((resolve, reject) => {
    getActiveOrdersCounter(resolve, reject)
  })
}

const getPromiseCart = (config = {}) => {
  return new Promise((resolve, reject) => {
    getCart(resolve, reject, config)
  })
}

const setPromiseCart = (data) => {
  return new Promise((resolve, reject) => {
    setCart(data, resolve, reject, { replaceCacheURL: '/cart' })
  })
}

export const getInfoCart = createAsyncThunk(
  'cart/getInfoCart',
  async (payload, { getState }) => {
    const res = await getPromiseCart(payload)
    return res
  },
)

export const setInfoCart = createAsyncThunk(
  'cart/setInfoCart',
  async (payload, { getState }) => {
    const res = await setPromiseCart(payload)
    return res
  },
)

export const deleteAllProducts = createAsyncThunk(
  'cart/deleteAllProducts',
  async (_, { getState }) => {
    const products = getState().cart.data.products
    const list = products.map(({ cartId, productId }) => ({ cartId, productId, quantity: 0 }))
    const res = await setPromiseCart({ products: list })
    return res
  },
)

export const getCounterActiveOrders = createAsyncThunk(
  'cart/getCounterActiveOrders',
  async (payload, { getState }) => {
    const res = await getPromiseCounterActiveOrders(payload)
    return res
  },
)

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    clearCart(state, action) {
        const isFullClear = action?.payload?.isFullClear || false
        if (isFullClear) {
            return initialState
        }

        return {
            ...initialState,
            shipping: state.shipping,
            payment: state.shipping,
            time: state.shipping,
        }
    },
  },
  extraReducers: builder => {
    builder.addCase(getInfoCart.fulfilled, (state, action) => {
      state.data = {
        ...action.payload,
      },
      state.status = 'success'
    })
    builder.addCase(setInfoCart.fulfilled, (state, action) => {
      state.data = {
        ...action.payload,
      }
    })
    builder.addCase(deleteAllProducts.fulfilled, (state, action) => {
      state.data = {
        ...action.payload,
      }
    })
    builder.addCase(getCounterActiveOrders.fulfilled, (state, action) => {
        state.activeOrderCount = action.payload || 0
    })
    builder.addCase(getCounterActiveOrders.rejected, (state) => {
        state.activeOrderCount = 0
    })
    builder.addCase(logoutUser, () => {
        return initialState
    })
  },
})

export const { clearCart } = cartSlice.actions
export default cartSlice.reducer
