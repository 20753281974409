const getSelectedItems = (options, ingredients = []) => {
  const selectedItems = []

  Object.entries(options).forEach(([key, items]) => {
    const group = ingredients.find(({ option_id }) => `${option_id}` === `${key}`)
    if (!group) {
      return
    }

    const list = group.items.filter(({ option_value_id }) => (
      items.some(({ option_value_id: idx }) => option_value_id === idx)
    ))

    selectedItems.push(...list)
  })

  return selectedItems
}

export default getSelectedItems
