import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getFormattedPrice } from '../../util/number'
import { getInfoCart } from '../../store/actions/cart'
import { cartInfoSelector } from '../../store/selectors/cart'
import { CHANGE_LAYOUT } from '../../constants/events'
import './styles.scss'

const CartBtn = ({ title = 'Корзина' }) => {
  const dispatch = useDispatch()
  const cartData = useSelector(cartInfoSelector)

  useEffect(() =>{
    dispatch(getInfoCart({ useCache: true }))
  }, [])

  useEffect(() => {
    window.dispatchEvent(new CustomEvent(CHANGE_LAYOUT))
  }, [cartData.products.length])

  if(!cartData.products.length){
    return null
  }

  return (
    <div className="grid-inner">
      <Link to="/Cart" className="cart-btn">
        <div className="cart-btn__label cart-btn__label--left">60 минут</div>
        <div className="cart-btn__text">{title}</div>
        <div className="cart-btn__label cart-btn__label--right">{getFormattedPrice(cartData.total)}₽</div>
      </Link>
    </div>
  )
}

export default CartBtn
