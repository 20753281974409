import { SIZE_LIST, SIZE_DEFAULT } from "../../constants/product"

const getGroupsByOptions = (options = []) => {
  const res = {}

  options.forEach(({ option_id, option_value_id, quantity }) => {
    if (res[option_id]) {
      res[option_id].push({ option_value_id, quantity })
    } else {
      res[option_id] = [{ option_value_id, quantity }]
    }
  })

  return res
}

const getSizeFromBasic = (groups, basics) => {
  let quantity = 0

  Object.entries(groups).find(([id, items]) => {
    // Среди всех групп находим основу
    const isBasic = basics.find(({ option_id }) => `${option_id}` === `${id}`)

    if (isBasic) {
      // В выбранной группе основ находим выбранный элемент с quantity
      const item = items.find(({ quantity }) => quantity >= 1 && quantity <= 3)
      quantity = item.quantity

      return true
    }

    return false
  })

  if (quantity) {
    return SIZE_LIST.find(({ coefficient }) => coefficient === quantity) || null
  }

  return null
}

const getSizeFromQuery = (querySize, groups, basics) => {
  // Получаем размер из query
  const size = SIZE_LIST.find(({ id }) => id === querySize)
  if (size) {
    return size
  }

  // Получаем размер из основ из query
  const sizeFromBasic = getSizeFromBasic(groups, basics)
  if (sizeFromBasic) {
    return sizeFromBasic
  }

  // Иначе устанавливаем дефолтную основу
  return SIZE_DEFAULT
}

export const getOptionsFromQuery = (search, basics = []) => {
  const paramsFromUrl = new URLSearchParams(search)
  const queryOptions = paramsFromUrl.get('options') || ''
  const querySize = paramsFromUrl.get('size') || ''

  const options = []
  if (queryOptions.length) {
    queryOptions.split('_').forEach((item) => {
      if (!item) {
        return
      }
      const [option_id, option_value_id, quantity] = item.split('-')

      options.push({
        option_id: +option_id,
        option_value_id: +option_value_id,
        quantity: +quantity,
      })
    })
  }

  const groups = getGroupsByOptions(options)
  const size = getSizeFromQuery(querySize, groups, basics)

  return {
    groups,
    size,
  }
}

const getListOptionsByFormat = (options, isFlatOptions = false) => {
  const result = []

  if (isFlatOptions) {
    // Перебираем плоский список (заказы и корзина)
    options.forEach((item) => {
      result.push({
        v: +item.option_value_id,
        i: +item.option_id,
        q: +item.quantity,
      })
    })
  } else {
    // Перебираем группы (детальная)
    Object.entries(options).map(([key, items]) => {
      items.forEach((item) => {
        result.push({
          v: +item.option_value_id,
          i: +item.option_id,
          q: +item.quantity,
        })
      })
    })
  }

  return result
}

export const getQueryFromOptions = (options, size = null, isFlatOptions = false) => {
  const list = getListOptionsByFormat(options, isFlatOptions)

  const result = list.map((item) => `${item.i}-${item.v}-${item.q}`)
  const query = result.join('_')
  const paramsObj = size ? { size: size.id, options: query } : { options: query }
  const params = new URLSearchParams(paramsObj)

  return params.toString()
}
