import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import clsx from 'clsx'
import nothingFoundImg from '../../img/nothing-found.png'
import './styles.scss'

const EmptyBlock = ({
  children = '',
  showBtn = false,
  type = '',
}) => {
  const history = useHistory()
  const onGoToCatalog = useCallback(() => {
    history.push('/Catalog')
  }, [])

  return (
    <div className={clsx('empty-block', type && `_${type}`)}>
      <div className="empty-block__body">
        <div className="grid-inner">
          {type === 'catalog' ? (
            <img className="empty-block__image" src={nothingFoundImg} />
          ) : <p className="empty-block__icon">👋</p>}
          <div className="empty-block__text">{children}</div>
          {showBtn ? (
            <div className="empty-block__btn">
              <Button onClick={onGoToCatalog} variant="primary">Перейти в каталог</Button>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default EmptyBlock
