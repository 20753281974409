
export const checkPointInPolygons = (ymaps, maps, polygons, coordinates) => {
  // Проверяем вхождение в зону доставки
  const point = new ymaps.GeoObject({
    geometry: {
      type: 'Point',
      coordinates: coordinates
    },
  })

  // Добавляем невидимую точку на карте
  point.options.set('visible', false)
  const objects = [point]
  const result = ymaps.geoQuery(objects).addToMap(maps)

  // Проверяем вхождение точки хотя бы в один полигон.
  const isContains = polygons.some((polygon) => {
    const objectsInsidePolygon = result.searchInside(polygon);
    return objectsInsidePolygon.getLength() > 0
  })

  // Удаляем точку с карты
  maps.geoObjects.remove(point);

  return isContains
}
