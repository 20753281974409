import React from 'react'

const Chat = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M1.25001 12C1.25001 6.06294 6.06295 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C10.113 22.75 8.33752 22.2632 6.79452 21.4077C6.70011 21.3554 6.60824 21.3531 6.54275 21.3762L4.02016 22.2676C2.47457 22.8138 0.98574 21.3249 1.53189 19.7793L2.46017 17.1523C2.48244 17.0893 2.48142 17.0013 2.43405 16.9092C1.67698 15.4367 1.25001 13.7671 1.25001 12ZM7.04982 13.2998C7.74017 13.2998 8.29982 12.7402 8.29982 12.0498C8.29982 11.3594 7.74017 10.7998 7.04982 10.7998C6.35946 10.7998 5.79982 11.3594 5.79982 12.0498C5.79982 12.7402 6.35946 13.2998 7.04982 13.2998ZM13.2998 12.0498C13.2998 12.7402 12.7402 13.2998 12.0498 13.2998C11.3595 13.2998 10.7998 12.7402 10.7998 12.0498C10.7998 11.3594 11.3595 10.7998 12.0498 10.7998C12.7402 10.7998 13.2998 11.3594 13.2998 12.0498ZM17.0498 13.2998C17.7402 13.2998 18.2998 12.7402 18.2998 12.0498C18.2998 11.3594 17.7402 10.7998 17.0498 10.7998C16.3595 10.7998 15.7998 11.3594 15.7998 12.0498C15.7998 12.7402 16.3595 13.2998 17.0498 13.2998Z" fill="#414C58"/>
    </svg>
  )
}

export default Chat
