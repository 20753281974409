import emptyImg from '../img/empty-image.png'

const tag = {
    default: true,
    image: emptyImg,
    name: '',
}

export const defaultTags = [
    tag,
    tag,
    tag,
    tag
]
